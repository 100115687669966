
export default class ServiceRecordService {
    async GetRecordServiceService(personId, positionId) {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Person/ServiceRecord?PersonId=${personId}&JobPositionId=${positionId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }
}