import { createStore } from 'vuex'

export default createStore({
  state: {
    datauser: null
  },
  getters: {
    isAuthenticated(state) {
      return state.datauser;
    },
  },
  mutations: {
    login(state, data) {
      state.datauser = data;
      localStorage.setItem('datauser', data);  
    },
    logout(state) {
      state.datauser = null;
      localStorage.removeItem('datauser');
    },
    selectedCompany(state, company) {
      if (state.datauser) {
        const data = JSON.parse(state.datauser);
        const nuevoData = { 
          ...data, 
          enterprise: company
        };

        state.datauser = JSON.stringify(nuevoData);        
        localStorage.setItem('datauser', state.datauser);
      }
    },
    initialiseStore(state) {
      if (localStorage.getItem('datauser')) {
        state.datauser = localStorage.getItem('datauser');
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
