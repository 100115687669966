<template>
    <div class="grid">
        
        <div class="col-12 lg:col-6 xl:col-12">
            <div class="card">
                <Toolbar class="mb-2 mt-0 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo Emo" icon="pi pi-plus" class="p-button-success mr-2" @click="OpenNewEmo()" />
                    </template>
                </Toolbar>

                <DataTable ref="dtEmo" 
                    :value="oListEmos"
                    dataKey="medicalExaminationId"
                    v-model:selection="oListSelected"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filters"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Seleccione un Emo</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="code" header="Código" :sortable="true"></Column>
                    <Column field="name" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.name }}</b><br/>
                        </template>
                    </Column>
                    <Column field="Status" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="flex justify-end gap-2">
                <Button type="button" label="Cancelar" severity="secondary" @click="closeModalHandler"></Button>
                <Button type="button" label="Aceptar" class="p-button-primary mr-2" @click="AddEmos()"></Button>
            </div>
        </div>

    </div>

</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref, defineEmits } from 'vue';
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import MedicalExaminationService from '@/service/MedicalExamination';

const router = useRouter();
const confirm = useConfirm();

const emosService = new MedicalExaminationService();

const filters = ref({});
const oListEmos = ref([]);
const bCargando = ref(false);
const oListSelected = ref([]);

const emit = defineEmits(['sendList', 'closeModal']);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadEmos();
} 

const InitializeFilters = () => {
    filters.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};


const LoadEmos = async() => {
    bCargando.value = true;
    const response = await emosService.GetMedicalExaminationService(0).then();
    if(response.code == 200){
        oListEmos.value = response.data.filter(x => x.status == 1);
        bCargando.value = false;
    }
}

const AddEmos = () => {
    if(oListSelected.value.length > 0){
        emit('sendList', oListSelected.value);
        closeModalHandler();
    }
    else{
        confirm.require({
            group: 'msgWarning',
            header: 'Advertencia',
            message: `Debe seleccionar al menos un emo`,
            accept: async() => {
                bCargando.value = false;
            }
        });

    }
}

const closeModalHandler = () => {
  emit('closeModal');
}



const OpenNewEmo = () => {
    let route = router.resolve({ path: `/portal/Emos/Mantenedor` });
    window.open(route.href, '_blank');
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
