<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
          <template #start>
            <h1>Nuevo Certificado</h1>
          </template>

          <template #end> </template>
        </Toolbar>
        <ConfirmDialog></ConfirmDialog>
        <div class="flex flex-column h-500rem">
          <div class="p-fluid p-formgrid grid">
            <div class="field col-12 md:col-4" v-if="certificateEditId != ''">
              <FloatLabel>
                <InputText id="txtCode" autocomplete="off" v-model="oCertificate.code" readonly />
                <label for="lblCode">Código</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <InputText id="txtName" autocomplete="off" v-model="oCertificate.name" :class="{ 'p-invalid' : validatedName }" />
                <label for="lblName">Nombre</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>

            <div class="field col-12 md:col-8">
              <FloatLabel>
                <Textarea id="txtDescription" rows="2" cols="30" v-model="oCertificate.description" />
                <label for="lblDescription">Descripción</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Calendar
                  showIcon
                  iconDisplay="input"
                  inputId="dtpFechaFin"
                  dateFormat="dd/mm/yy"
                  v-model="oCertificate.startDate"
                  :class="{ 'p-invalid' : validatedStartDate }"
                />
                <label for="lblFechaFin">Fecha Inicio</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Calendar
                  showIcon
                  iconDisplay="input"
                  inputId="dtpFechaFin"
                  dateFormat="dd/mm/yy"
                  v-model="oCertificate.endDate"
                  :class="{ 'p-invalid' : validatedEndDate }"
                />
                <label for="lblFechaFin">Fecha Fin</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  v-model="oCertificate.validityTimeId"
                  :options="oListTimesValidity"
                  optionLabel="name"
                  optionValue="validityTimeId"
                  placeholder=""
                  class="w-full"
                  :class="{ 'p-invalid' : validatedValidityTimeId }"
                />
                <label for="lblValidityTime">Tiempo de Vigencia</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  v-model="oCertificate.renewalTimeId"
                  :options="oListTimesRenewal"
                  optionLabel="name"
                  optionValue="renewalTimeId"
                  placeholder=""
                  class="w-full"
                  :class="{ 'p-invalid' : validatedRenewalTimeId }"
                />
                <label for="lblRenewal">Tiempo para Renovación</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  :options="oListMode"
                  optionLabel="name"
                  optionValue="modeId"
                  placeholder=""
                  class="w-full"
                  v-model="oCertificate.modeId"
                  :class="{ 'p-invalid' : validatedMode }"
                />
                <label for="lblMode">Modalidad</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <div class="flex justify-content">
                <Checkbox v-model="checked" :binary="true" />
                <label for="ingredient4" class="ml-2">
                  Tiene Pre-Requisitos
                </label>
              </div>
            </div>
          </div>
        </div>

        <div v-if="checked">
          <Divider layout="horizontal" />
          <h5>Pre-Requisitos</h5>
          <div class="flex flex-column h-500rem mt-5">
            <div class="p-fluid p-formgrid grid">
              <div class="field col-12 md:col-4">
                <FloatLabel>
                  <InputText
                    id="txtCodigoProducto"
                    v-model="nameReq"
                    autocomplete="off"
                  />
                  <label for="lblCodigoProducto">Nombre</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-2">
                <FloatLabel>
                  <Calendar
                    showIcon
                    iconDisplay="input"
                    inputId="dtpFechaFin"
                    dateFormat="dd/mm/yy"
                    :class="{ 'p-invalid': fechaFinValido }"
                  />
                  <label for="lblFechaFin">Vigencia</label>
                </FloatLabel>
              </div>
              <div class="field col-12 md:col-2">
                <Button
                  type="button"
                  label="Agregar"
                  icon="pi pi-plus"
                  class="p-button-info mr-2"
                ></Button>
              </div>

              <div class="col-12 md:col-8">
                <DataTable
                  ref="dtDetalle"
                  :value="oListaDetalle"
                  dataKey="DetalleId"
                  :paginator="false"
                  :rows="10"
                  :rowsPerPageOptions="[10, 25, 50, 100]"
                  :loading="bCargando"
                  class="p-datatable-sm"
                >
                  <template #header>
                    <div
                      class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
                    >
                      <h5 class="m-0">Detalle Requisitos</h5>
                    </div>
                  </template>

                  <Column field="Name" header="Nombre" :sortable="true">
                    <template #body="slotProps">
                      <b>{{ slotProps.data.Name }}</b
                      ><br />
                      {{ slotProps.data.Code }}
                    </template>
                  </Column>
                  <Column
                    field="Validity"
                    header="Vigencia"
                    :sortable="true"
                  ></Column>
                  <Column
                    style="text-align: center"
                    headerStyle="min-width:12rem;"
                  >
                    <template #body="slotProps">
                      <Button
                        icon="pi pi-trash"
                        class="p-button-rounded mt-2 p-button-danger"
                        @click="QuitarDetalle(slotProps.data)"
                      />
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider layout="horizontal" />
        <div class="flex justify-content-start">
          <Button
            label="Guardar"
            icon="pi pi-save"
            severity="primary"
            iconPos="right"
            @click="SaveCertificate()"
            :loading="bCargando"
          />
          <Button
            label="Atrás"
            icon="pi pi-arrow-left"
            class="ml-2"
            severity="secondary"
            @click="BackTo"
          ></Button>
        </div>
      </div>
    </div>
  </div>

  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
import CertificateService from "@/service/CertificateService";
import ModeService from "@/service/ModeService";
import TimesRenewalService from "@/service/TimesRenewalService";
import ValidityTimeService from "@/service/ValidityTimeService";
import Crypto from "@/utilitarios/Crypto";
import Utilitario from "@/utilitarios/Utilitario";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();
const toast = useToast();
const store = useStore();
const confirm = useConfirm();

const validityTimeService = new ValidityTimeService();
const timesRenewalService = new TimesRenewalService();
const modeService = new ModeService();
const certificateService = new CertificateService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const certificateEditId = ref('');
const oListTimesValidity = ref([]);
const oListTimesRenewal = ref([]);
const oListMode = ref([]);

const validatedName = ref(false);
const validatedStartDate = ref(false);
const validatedEndDate = ref(false);
const validatedValidityTimeId = ref(false);
const validatedRenewalTimeId = ref(false);
const validatedMode = ref(false);

const oCertificate = ref({
  certificateId: 0,
  code: '',
  description: ''
});

const oListaDetalle = ref([
  {
    DetalleId: 1,
    Code: "R01",
    Name: "Operación de Equipo Liviano",
    Type: 1,
    TypeName: "Check",
    Validity: "01/12/2024",
  },
  {
    DetalleId: 2,
    Code: "R02",
    Name: "Operación de Equipo Pesado",
    Type: 2,
    TypeName: "Númerico",
    Validity: "01/12/2024",
  },
  {
    DetalleId: 3,
    Code: "R03",
    Name: "Trabajos en Altura Estructural> 1.8m",
    Type: 3,
    TypeName: "Texto",
    Validity: "01/12/2024",
  },
]);

const checked = ref(false);

//#region Eventos
onMounted(() => {
  Initialize();
});
//#endregion

//#region Metodos
const Initialize = () => {
  LoadValidityTime();
  LoadTimesRenewal();
  LoadMode();
  certificateEditId.value = Crypto.Desencriptar(route.params.id);
  if(!Utilitario.StringIsNullOrEmpty(certificateEditId.value)){
    LoadCertificate(certificateEditId.value);
  }
};

const BackTo = () => {
  router.push({ path: `/portal/Certificaciones/Mantenedor` });
};

const LoadValidityTime = async() => {
  bCargando.value = true;
    const response = await validityTimeService.GetValidityTimeService().then();
    if(response.code == 200){
      oListTimesValidity.value = response.data;
      bCargando.value = false;
    }
}

const LoadTimesRenewal = async() => {
    bCargando.value = true;
    const response = await timesRenewalService.GetTimesRenewalService().then();
    if(response.code == 200){
      oListTimesRenewal.value = response.data;
      bCargando.value = false;
    }
}

const LoadMode = async() => {
    bCargando.value = true;
    const response = await modeService.GetModeService().then();
    if(response.code == 200){
      oListMode.value = response.data;
      bCargando.value = false;
    }
}

const LoadCertificate = async(id) => {
  bCargando.value = true;
    const response = await certificateService.GetCertificateService(id).then();
    if(response.code == 200){
        oCertificate.value = response.data;
        oCertificate.value.startDate = Utilitario.formatoDate(response.data.startDate);
        oCertificate.value.endDate = Utilitario.formatoDate(response.data.endDate);
        oCertificate.value.validityTimeId = response.data.validityTime.validityTimeId;
        oCertificate.value.renewalTimeId = response.data.renewalTime.renewalTimeId;
        oCertificate.value.modeId = response.data.mode.modeId;
        bCargando.value = false;
    }
}

const SaveCertificate = async() => {
  if(await ValidateCertificate()){
    CreateUpdateCertificate();
  }
  else{
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
  }
}

const ValidateCertificate = async() => {
  const { ...model } = oCertificate.value;
  const valid = ref(false);
  
  if(!model?.name){
    validatedName.value = true;
    valid.value = true;
  }
  else{
    validatedName.value = false;
  }

  if(!model?.startDate){
    validatedStartDate.value = true;
    valid.value = true;
  }
  else{
    validatedStartDate.value = false;
  }

  if(!model?.endDate){
    validatedEndDate.value = true;
    valid.value = true;
  }
  else{
    validatedEndDate.value = false;
  }

  if(!model?.validityTimeId){
    validatedValidityTimeId.value = true;
    valid.value = true;
  }
  else{
    validatedValidityTimeId.value = false;
  }

  if(!model?.renewalTimeId){
    validatedRenewalTimeId.value = true;
    valid.value = true;
  }
  else{
    validatedRenewalTimeId.value = false;
  }

  if(!model?.modeId){
    validatedMode.value = true;
    valid.value = true;
  }
  else{
    validatedMode.value = false;
  }

  return !valid.value;
}

const CreateUpdateCertificate = async() => {
  const { ...model } = oCertificate.value;
  
  const request = {
    ...model,
    mode: {
      modeId: model.modeId,
      name: ''
    },
    renewalTime: {
      renewalTimeId: model.renewalTimeId,
      name: ''
    },
    validityTime: {
      validityTimeId: model.validityTimeId,
      name: ''
    },
    creationUser: userStore.user.idUser
  };
  
  confirm.require({
      message: `¿Está seguro de guardar el Certificado?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{

          const response = await certificateService.CreateUpdateCertificateService(request).then();
          if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se guardó el Certificado #${response.data.code} correctamente`,
                accept: async() => {
                    bCargando.value = false;
                    router.push({ path: `/portal/Certificaciones/Mantenedor` });
                }
            });
          }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar:`, error);
        }
      }
  });

}

const QuitarDetalle = () => {}
//#endregion
</script>