export default class UsuarioService {
    async loginService(request) {

        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Security/access`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async ListarUsuariosService() {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/Security/user');
        return await response.json();
    }

    async CrearUsuarioService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/mantenedor/crearusuario', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

    async updatePasswordService(request) {
        const response = await fetch(process.env.VUE_APP_API_IOT + '/v1/user/updatepassword', {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(request),
        });

        return response.json();
    }

}