<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
          <template #start>
            <h1>Nuevo Examen Médico</h1>
          </template>

          <template #end> </template>
        </Toolbar>
        <ConfirmDialog></ConfirmDialog>
        <div class="flex flex-column h-500rem">
          <div class="p-fluid p-formgrid grid">
            <div class="field col-12 md:col-4" v-if="emoEditId != ''">
              <FloatLabel>
                <InputText id="txtCode" v-model="oEmo.code" autocomplete="off" readonly />
                <label for="lblCode">Código</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  :options="oListProfileType"
                  optionLabel="name"
                  optionValue="profileTypeId"
                  placeholder=""
                  v-model="oEmo.profileTypeId"
                  class="w-full"
                  :class="{ 'p-invalid' : validatedProfileTypeId }"
                />
                <label for="lblUnidadMedida">Tipo de perfil</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-8">
              <FloatLabel>
                <InputText id="txtName" v-model="oEmo.name" autocomplete="off" :class="{ 'p-invalid' : validatedName }" />
                <label for="lblName">Nombre</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-8">
              <FloatLabel>
                <Textarea id="txtReferencia" v-model="oEmo.description" rows="2" cols="30" />
                <label for="lblReferencia">Descripción</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  v-model="oEmo.validityTimeId"
                  :options="oListTimesValidity"
                  optionLabel="name"
                  optionValue="validityTimeId"
                  placeholder=""
                  class="w-full"
                  :class="{ 'p-invalid' : validatedValidityTimeId }"
                />
                <label for="lblValidityTime">Tiempo de Vigencia</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4">
              <FloatLabel>
                <Dropdown
                  v-model="oEmo.renewalTimeId"
                  :options="oListTimesRenewal"
                  optionLabel="name"
                  optionValue="renewalTimeId"
                  placeholder=""
                  class="w-full"
                  :class="{ 'p-invalid' : validatedRenewalTimeId }"
                />
                <label for="lblRenewal">Tiempo para Renovación</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-4"></div>
            <div class="field col-12 md:col-4">
              <div class="flex justify-content">
                <Checkbox v-model="checked" :binary="true" />
                <label for="ingredient4" class="ml-2">
                  Tiene Pre-Requisitos
                </label>
              </div>
            </div>
            <div class="field col-12 md:col-8"></div>
          </div>
        </div>

        <div v-if="checked">
          <Divider layout="horizontal" />
          <h5>Pre-Requisitos</h5>
          <div class="flex flex-column h-500rem mt-5">
            <div class="p-fluid p-formgrid grid">
              <div class="field col-12 md:col-6"></div>
              <div class="field col-12 md:col-2">
                <Button
                  type="button"
                  label="Agregar"
                  icon="pi pi-plus"
                  class="p-button-info mr-2"
                  @click="modalSearchPersonal = true"
                ></Button>
              </div>

              <div class="col-12 md:col-8">
                <DataTable
                  ref="dtDetalle"
                  :value="oListaDetalle"
                  dataKey="DetalleId"
                  :paginator="false"
                  :rows="10"
                  :rowsPerPageOptions="[10, 25, 50, 100]"
                  :loading="bCargando"
                  class="p-datatable-sm"
                >
                  <template #header>
                    <div
                      class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
                    >
                      <h5 class="m-0">Detalle Requisitos</h5>
                    </div>
                  </template>

                  <Column field="Name" header="Nombre" :sortable="true">
                    <template #body="slotProps">
                      <b>{{ slotProps.data.Name }}</b
                      ><br />
                      {{ slotProps.data.Code }}
                    </template>
                  </Column>
                  <Column
                    field="Validity"
                    header="Vigencia"
                    :sortable="true"
                  ></Column>
                  <Column
                    style="text-align: center"
                    headerStyle="min-width:12rem;"
                  >
                    <template #body="slotProps">
                      <Button
                        icon="pi pi-trash"
                        class="p-button-rounded mt-2 p-button-danger"
                        @click="QuitarDetalle(slotProps.data)"
                      />
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        <Divider layout="horizontal" />
        <div class="flex justify-content-start">
          <Button
            label="Guardar"
            icon="pi pi-save"
            severity="primary"
            iconPos="right"
            @click="SaveMedicalExamination()"
            :loading="bCargando"
          />
          <Button
            label="Atrás"
            icon="pi pi-arrow-left"
            class="ml-2"
            severity="secondary"
            @click="BackTo"
          ></Button>
        </div>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="modalSearchPersonal" modal header="Buscar Emo" :style="{ width: '70rem' }">
      <SearchEMO @closeModal="closeModal" @sendList="AddPersonal" /> 
  </Dialog>

  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import SearchEMO from './SearchEMO/SearchEMO.vue';
import ProfileTypeService from "@/service/ProfileTypeService";
import ValidityTimeService from "@/service/ValidityTimeService";
import TimesRenewalService from "@/service/TimesRenewalService";
import MedicalExaminationService from "@/service/MedicalExamination";
import { useStore } from "vuex";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Utilitario from "@/utilitarios/Utilitario";
import Crypto from "@/utilitarios/Crypto";

const router = useRouter();
const route = useRoute();
const store = useStore();
const toast = useToast();
const confirm = useConfirm();

const profileTypeService = new ProfileTypeService();
const validityTimeService = new ValidityTimeService();
const timesRenewalService = new TimesRenewalService();
const medicalExaminationService = new MedicalExaminationService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const oListProfileType = ref([]);
const oListTimesValidity = ref([]);
const oListTimesRenewal = ref([]);
const oEmo = ref({
  medicalExaminationId: 0,
  code: '',
  description: ''
});

const oListaDetalle = ref([]);

const validatedProfileTypeId = ref(false);
const validatedName = ref(false);
const validatedValidityTimeId = ref(false);
const validatedRenewalTimeId = ref(false);

const checked = ref(false);
const modalSearchPersonal = ref(false);
const emoEditId = ref('');

//#region Eventos
onMounted(() => {
  Initialize();
});
//#endregion

//#region Metodos
const Initialize = () => {
  LoadProfileType();
  LoadValidityTime();
  LoadTimesRenewal();
  emoEditId.value = Crypto.Desencriptar(route.params.id);
  if(!Utilitario.StringIsNullOrEmpty(emoEditId.value)){
    LoadMedicalExamination(emoEditId.value);
  }
};

const BackTo = () => {
  router.push({ path: `/portal/Emos/Mantenedor` });
};

const QuitarDetalle = () => {}

const closeModal = () => {
  modalSearchPersonal.value = false;
}

const LoadProfileType = async() => {
  bCargando.value = true;
    const response = await profileTypeService.GetProfileTypeService().then();
    if(response.code == 200){
      oListProfileType.value = response.data;
        bCargando.value = false;
    }
}

const LoadValidityTime = async() => {
  bCargando.value = true;
    const response = await validityTimeService.GetValidityTimeService().then();
    if(response.code == 200){
      oListTimesValidity.value = response.data;
      bCargando.value = false;
    }
}

const LoadTimesRenewal = async() => {
    bCargando.value = true;
    const response = await timesRenewalService.GetTimesRenewalService().then();
    if(response.code == 200){
      oListTimesRenewal.value = response.data;
      bCargando.value = false;
    }
}

const LoadMedicalExamination = async(id) => {
    bCargando.value = true;
    const response = await medicalExaminationService.GetMedicalExaminationService(id).then();
    if(response.code == 200){
        oEmo.value = response.data;
        oEmo.value.profileTypeId = response.data.profileType.profileTypeId;
        oEmo.value.validityTimeId = response.data.validityTime.validityTimeId;
        oEmo.value.renewalTimeId = response.data.renewalTime.renewalTimeId;
        bCargando.value = false;
    }
}

const SaveMedicalExamination = async() => {
  if(await ValidateMedicalExamination()){
      CreateUpdateMedicalExamination();
  }
  else{
      toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
  }
}

const ValidateMedicalExamination = async() => {
  const { ...model } = oEmo.value;
  const valid = ref(false);
  if(!model?.profileTypeId){
    validatedProfileTypeId.value = true;
    valid.value = true;
  }
  else{
    validatedProfileTypeId.value = false;
  }

  if(!model?.name){
    validatedName.value = true;
    valid.value = true;
  }
  else{
    validatedName.value = false;
  }

  if(!model?.validityTimeId){
    validatedValidityTimeId.value = true;
    valid.value = true;
  }
  else{
    validatedValidityTimeId.value = false;
  }

  if(!model?.renewalTimeId){
    validatedRenewalTimeId.value = true;
    valid.value = true;
  }
  else{
    validatedRenewalTimeId.value = false;
  }

  return !valid.value;
}

const CreateUpdateMedicalExamination = async() => {
  const { ...model } = oEmo.value;

  const request = {
    ...model,
    profileType: {
      profileTypeId: model.profileTypeId,
      name: ''
    },
    renewalTime: {
      renewalTimeId: model.renewalTimeId,
      name: ''
    },
    validityTime: {
      validityTimeId: model.validityTimeId,
      name: ''
    },
    enterprise: {
      enterpriseId: userStore.enterprise.enterpriseId,
      name: ''
    },
    creationUser: userStore.user.idUser
  };
  
  confirm.require({
      message: `¿Está seguro de guardar el EMO?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{

          const response = await medicalExaminationService.CreateMedicalExaminationService(request).then();
          if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se guardó el EMO #${response.data.code} correctamente`,
                accept: async() => {
                    bCargando.value = false;
                    router.push({ path: `/portal/Emos/Mantenedor` });
                }
            });
          }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar:`, error);
        }
      }
  });

}
//#endregion
</script>