import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/styles.scss';
import 'primevue/resources/themes/aura-light-blue/theme.css'
import "primeflex/primeflex.css";

import PrimeVue from 'primevue/config';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import InputMask from 'primevue/inputmask';
import ToggleButton from 'primevue/togglebutton';
import Checkbox from 'primevue/checkbox';
import StyleClass from 'primevue/styleclass';
import Dropdown from 'primevue/dropdown';
import Chart from 'primevue/chart';
import InputNumber from 'primevue/inputnumber';
import Card from 'primevue/card';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import FileUpload from 'primevue/fileupload';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Rating from 'primevue/rating';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import DynamicDialog from 'primevue/dynamicdialog';
import Textarea from 'primevue/textarea';
import RadioButton from 'primevue/radiobutton';
import ProgressSpinner from 'primevue/progressspinner';
import AutoComplete from 'primevue/autocomplete';
import Menu from 'primevue/menu';
import Listbox from 'primevue/listbox'
import Calendar from 'primevue/calendar';
import Tag from 'primevue/tag';
import Message from 'primevue/message';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import Steps from 'primevue/steps';
import FloatLabel from 'primevue/floatlabel';
import Fieldset  from 'primevue/fieldset';
import Divider from 'primevue/divider';
import Badge from 'primevue/badge';
import SplitButton from 'primevue/splitbutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Galleria from 'primevue/galleria';
import Skeleton from 'primevue/skeleton';
import Avatar from 'primevue/avatar';
import Chip from 'primevue/chip';
import MeterGroup from 'primevue/metergroup';
import OverlayPanel from 'primevue/overlaypanel';
import MultiSelect from 'primevue/multiselect';

createApp(App)
    .use(PrimeVue, { ripple: true }, { locale: {
            firstDayOfWeek: 1,
            dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
            dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
            today: "Hoy",
            clear: "Limpiar",
            dateFormat: "dd/mm/yy",
            weekHeader: "Sem"
        }
    })
    .use(ToastService)
    .use(store)
    .use(router)
    .use(DialogService)
    .use(ConfirmationService)
    .directive('styleclass', StyleClass)
    .directive('tooltip', Tooltip)
    .component('InputText', InputText)
    .component('Button', Button)
    .component('Password', Password)
    .component('InputMask', InputMask)
    .component('ToggleButton', ToggleButton)
    .component('Checkbox', Checkbox)
    .component('Dropdown', Dropdown)
    .component('Chart', Chart)
    .component('InputNumber', InputNumber)
    .component('Card', Card)
    .component('Toast', Toast)
    .component('Toolbar', Toolbar)
    .component('FileUpload', FileUpload)
    .component('DataTable', DataTable)
    .component('DataViewLayoutOptions', DataViewLayoutOptions)
    .component('DataView', DataView)
    .component('Column', Column)
    .component('ColumnGroup', ColumnGroup)
    .component('Rating', Rating)
    .component('ConfirmDialog', ConfirmDialog)
    .component('Dialog', Dialog)
    .component('DynamicDialog', DynamicDialog)
    .component('Textarea', Textarea)
    .component('RadioButton', RadioButton)
    .component('ProgressSpinner', ProgressSpinner)
    .component('AutoComplete', AutoComplete)
    .component('Menu', Menu)
    .component('Listbox', Listbox)
    .component('Calendar', Calendar)
    .component('Tag', Tag)
    .component('Message', Message)
    .component('Tree', Tree)
    .component('Stepper', Stepper)
    .component('StepperPanel', StepperPanel)
    .component('Steps', Steps)
    .component('FloatLabel', FloatLabel)
    .component('Fieldset', Fieldset)
    .component('Divider', Divider)
    .component('Badge', Badge)
    .component('SplitButton', SplitButton)
    .component('TabView', TabView)
    .component('TabPanel', TabPanel)
    .component('Galleria', Galleria)
    .component('Skeleton', Skeleton)
    .component('Avatar', Avatar)
    .component('Chip', Chip)
    .component('MeterGroup', MeterGroup)
    .component('OverlayPanel', OverlayPanel)
    .component('MultiSelect', MultiSelect)
    .mount('#app')