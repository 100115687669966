<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="OpenCourseNew" />
                    </template>
                </Toolbar>
                <ConfirmDialog></ConfirmDialog>
                <DataTable ref="dtCourse" 
                    :value="oListCourse"
                    dataKey="courseId"
                    :rowHover="true"
                    :paginator="false" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Cursos</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column style="text-align: center;" headerStyle="min-width:2rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2" @click="EditCourse(slotProps.data.courseId)" />
                            <Button icon="pi pi-power-off" v-tooltip.top="slotProps.data.status ? 'Desactivar' : 'Activar'" 
                                    class="p-button-rounded mt-2" :class="slotProps.data.status ? 'p-button-danger' : 'p-button-secondary'" @click="UpdateStatusCourse(slotProps.data)" />
                        </template>
                    </Column>

                    <Column field="code" header="Código" :sortable="true"></Column>
                    <Column field="name" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.name }}</b><br/>
                        </template>
                    </Column>
                    
                    <Column field="Status" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
</template>
<script setup>
import CourseService from '@/service/CourseService';
import Crypto from '@/utilitarios/Crypto';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();
const confirm = useConfirm();

const courseService = new CourseService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const filtros = ref({});

const oListCourse = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadCourses();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const OpenCourseNew = () => {
    router.push({ path: `/portal/Curso/Nuevo` });
}

const EditCourse = (courseId) => {
    const id = encodeURIComponent(Crypto.Encriptar(courseId));
    router.push({ path: `/portal/Curso/Editar/${id}` });
}

const LoadCourses = async() => {
    bCargando.value = true;
    const response = await courseService.GetCourseService(0).then();
    if(response.code == 200){
        oListCourse.value = response.data;
        bCargando.value = false;
    }
}

const UpdateStatusCourse = async(item) => {
    const actionName = item.status ? 'desactivar' : 'activar';
    const confirmationName = item.status ? 'desactivó' : 'activó';
    const request = {
        courseId: item.courseId,
        status: !item.status,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} el curso #${item.code}?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await courseService.UpdateStatusCourseService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} el curso #${response.data.code} correctamente`,
                accept: async() => {
                    LoadCourses();
                    bCargando.value = false;
                }
            });
        }
      }
    });
} 
//#endregion
</script>