<template>
    
    <div class="grid">
        
        <div class="col-12 lg:col-6 xl:col-12">
            <div class="card">
                <Toolbar class="mb-2 mt-0 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo Curso" icon="pi pi-plus" class="p-button-success mr-2" @click="OpenNewCourse()" />
                    </template>
                </Toolbar>

                <DataTable ref="dtCourse" 
                    :value="oListCourse"
                    dataKey="courseId"
                    v-model:selection="oListSelected"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Seleccione un Curso</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="code" header="Código" :sortable="true"></Column>
                    <Column field="name" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.name }}</b><br/>
                        </template>
                    </Column>
                    <Column field="Status" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="flex justify-end gap-2">
                <Button type="button" label="Cancelar" severity="secondary" @click="closeModalHandler"></Button>
                <Button type="button" label="Aceptar" class="p-button-primary mr-2" @click="AddCourse()"></Button>
            </div>
        </div>

    </div>

</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { defineEmits } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import CourseService from '@/service/CourseService';

const router = useRouter();
const confirm = useConfirm();

const filtros = ref({});
const oListCourse = ref([]);
const bCargando = ref(false);
const oListSelected = ref([]);

const courseService = new CourseService();

const emit = defineEmits(['sendList', 'closeModal']);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadCourses();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadCourses = async() => {
    bCargando.value = true;
    const response = await courseService.GetCourseService(0).then();
    if(response.code == 200){
        oListCourse.value = response.data.filter(x => x.status == 1);
        bCargando.value = false;
    }
}

const AddCourse = () => {
    if(oListSelected.value.length > 0){
        emit('sendList', oListSelected.value);
        closeModalHandler();
    }
    else{
        confirm.require({
            group: 'msgWarning',
            header: 'Advertencia',
            message: `Debe seleccionar al menos un curso`,
            accept: async() => {
                bCargando.value = false;
            }
        });
    }
}

const closeModalHandler = () => {
  emit('closeModal');
}

const OpenNewCourse = () => {
    let route = router.resolve({ path: `/portal/Cursos/Mantenedor` });
    window.open(route.href, '_blank');
}
//#endregion
</script>
