<template>
    <TabView>
        <TabPanel header="Datos personales">
            <div class="grid mt-3">
                <div class="col-12 lg:col-6 xl:col-12">
                    <div class="flex flex-column h-500rem">
                        <div class="p-fluid p-formgrid grid">

                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText id="txtFirstName" autocomplete="off" v-model="oPerson.firstName" 
                                    :class="{ 'p-invalid' : validatedFirstName }" />
                                    <label for="lblFirstName">Nombres completos</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText id="txtLastName" autocomplete="off" v-model="oPerson.lastName" 
                                    :class="{ 'p-invalid' : validatedLastName }" />
                                    <label for="lblLastName">Apellidos</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListDocumentType" v-model="oPerson.documentTypeId"
                                        optionLabel="name" optionValue="documentTypeId" class="w-full" disabled />
                                    <label for="lblDocumentType">Tipo documento</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText id="txtDocumentNumber" autocomplete="off" v-model="oPerson.documentNumber"
                                    :class="{ 'p-invalid' : validatedDocumentNumber }" />
                                    <label for="lblDocumentNumber">Número DNI</label>
                                </FloatLabel>
                            </div>
                            
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oPerson.documentExpirationDate"
                                        inputId="dtpBirthdate" dateFormat="dd/mm/yy" 
                                        :class="{ 'p-invalid' : validatedDocumentExpirationDate }" />
                                    <label for="lblBirthdate">Fecha vcto DNI</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oPerson.birthdate"
                                        inputId="dtpBirthdate" dateFormat="dd/mm/yy" 
                                        :class="{ 'p-invalid' : validatedBirthDate }" />
                                    <label for="lblBirthdate">Fecha nacimiento</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText id="txtCellphone" autocomplete="off" v-model="oPerson.cellphone" />
                                    <label for="lblCellphone">Celular</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText id="txtCodigoProducto" autocomplete="off" v-model="oPerson.email" />
                                    <label for="lblCodigoProducto">Correo</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListState" v-model="oPerson.stateId"
                                        optionLabel="name" optionValue="stateId" class="w-full" @change="LoadProvince()" 
                                        :class="{ 'p-invalid' : validatedState }" />
                                    <label for="lblUnidadMedida">Departamento</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListProvince" v-model="oPerson.provinceId"
                                        optionLabel="name" optionValue="provinceId" class="w-full" 
                                        :class="{ 'p-invalid' : validatedProvince }" />
                                    <label for="lblUnidadMedida">Provincia</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText id="txtDistrict" autocomplete="off" v-model="oPerson.district"
                                    :class="{ 'p-invalid' : validatedDistrict }" />
                                    <label for="lblDistrict">Distrito</label>
                                </FloatLabel>
                            </div>
                            
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListStatusPerson" v-model="oPerson.statusPersonId"
                                        optionLabel="name" optionValue="statusPersonId" class="w-full"  />
                                    <label for="lblStatusPerson">Estado</label>
                                </FloatLabel>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel header="Documentos personales">
            <div class="grid mt-3">
                <div class="col-12 lg:col-6 xl:col-12">
                    <div class="flex flex-column h-500rem">
                        <div class="p-fluid p-formgrid grid">
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText id="txtPassportNumber" autocomplete="off" v-model="oPerson.passportNumber" />
                                    <label for="lblPassportNumber">N° Pasaporte</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oPerson.passportExpirationDate"
                                        inputId="dtpPassportExpirationDate" dateFormat="dd/mm/yy"  />
                                    <label for="lblPassportExpirationDate">Vencimiento pasaporte</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputText id="txtVisaNumber" autocomplete="off" v-model="oPerson.visaNumber" />
                                    <label for="lblVisaNumber">N° Visa</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oPerson.visaExpirationDate"
                                        inputId="dtpVisaExpirationDate" dateFormat="dd/mm/yy" />
                                    <label for="lblVisaExpirationDate">Vencimiento VISA</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListVisaSituation" v-model="oPerson.visaSituationId"
                                        optionLabel="name" optionValue="visaSituationId" class="w-full"  />
                                    <label for="lblVisaSituation">Situación VISA</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListSingleWorkCertificateType" v-model="oPerson.singleWorkCertificateTypeId"
                                        optionLabel="name" optionValue="singleWorkCertificateTypeId"  class="w-full"  />
                                    <label for="lblUnidadMedida">Certiadulto/certijoven</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListRecord" v-model="oPerson.recordTypeId"
                                        optionLabel="name" optionValue="recordTypeId" class="w-full"  />
                                    <label for="lblUnidadMedida">Antecedentes</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListProfession" v-model="oPerson.professionId" @change="LoadSpecialty()"
                                        optionLabel="name" optionValue="professionId" class="w-full"  />
                                    <label for="lblUnidadMedida">Profesión</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListSpecialty" v-model="oPerson.specialtyId"
                                        optionLabel="name" optionValue="specialtyId" class="w-full"  />
                                    <label for="lblUnidadMedida">Especialidad</label>
                                </FloatLabel>
                            </div>

                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Calendar showIcon iconDisplay="input" v-model="oPerson.startDateWorkExperience"
                                        inputId="dtpBirthdate" dateFormat="dd/mm/yy" 
                                        :class="{ 'p-invalid' : validatedBirthDate }" />
                                    <label for="lblBirthdate">Fecha inicio trabajo</label>
                                </FloatLabel>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel header="EPPs">
            <div class="grid mt-3">
                <div class="col-12 lg:col-6 xl:col-12">
                    <div class="flex flex-column h-500rem">
                        <div class="p-fluid p-formgrid grid">
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <Dropdown :options="oListShirtSize" v-model="oPerson.shirtSizeId"
                                        optionLabel="name" optionValue="shirtSizeId" class="w-full"  />
                                    <label for="lblShirtSize">Talla camisa</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6"></div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputNumber v-model="oPerson.pantsSize" inputId="minmax" showButtons mode="decimal" :min="0" :max="100" />
                                    <label for="lblPantsSizeId">Talla pantalon</label>
                                </FloatLabel>
                            </div>
                            <div class="field col-12 md:col-6"></div>
                            <div class="field col-12 md:col-6">
                                <FloatLabel>
                                    <InputNumber v-model="oPerson.shoesSize" inputId="minmax" showButtons mode="decimal" :min="0" :max="100" />
                                    <label for="lblShoesSize">Talla zapatos</label>
                                </FloatLabel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>

    </TabView>
    <div class="grid">        
        <div class="col-12 lg:col-6 xl:col-12">
            <Divider layout="horizontal" class="mt-0 pt-0" />
            <div class="flex justify-content-start">
                <Button label="Cancelar" class="ml-2" severity="secondary" @click="closeModalHandler"></Button>
                <Button label="Guardar"  severity="primary" iconPos="right" @click="SavePerson()" :loading="bCargando" />
            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

    <ConfirmDialog group="msgWarning">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-warning inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-exclamation-triangle text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>

</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref, defineEmits, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useConfirm } from 'primevue/useconfirm';
import CountryService from '@/service/CountryService';
import CommonService from '@/service/CommonService';
import ProfessionService from '@/service/ProfessionService';
import SpecialtyService from '@/service/SpecialtyService';
import PersonService from '@/service/PersonService';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { usePrimeVue } from 'primevue/config';
import Utilitario from '@/utilitarios/Utilitario';
import Crypto from '@/utilitarios/Crypto';

const router = useRouter();
const confirm = useConfirm();
const store = useStore();
const toast = useToast();

const countryService = new CountryService();
const professionService = new ProfessionService();
const specialtyService = new SpecialtyService();
const personService = new PersonService();
const commonService = new CommonService();

const userStore = JSON.parse(store.state.datauser);

const filtros = ref({});
const oPerson = ref({
    personId: 0,
    cellphone: '',
    email: '',
    statusPersonId: 1,
    documentTypeId: 1,
    status: true 
});

const bCargando = ref(false);
const oListRecord = ref([]);
const oListProfession = ref([]);
const oListSpecialty = ref([]);
const oListState = ref([]);
const oListProvince = ref([]);
const oListVisaSituation = ref([]);
const oListSingleWorkCertificateType = ref([]);
const oListStatusPerson = ref([]);
const oListDocumentType = ref([]);
const oListShirtSize = ref([
    { name: 'XS', shirtSizeId: 'XS' },    
    { name: 'S', shirtSizeId: 'S' },
    { name: 'M', shirtSizeId: 'M' },
    { name: 'L', shirtSizeId: 'L' },
    { name: 'XL', shirtSizeId: 'XL' }
]);

const validatedFirstName = ref(false);
const validatedLastName = ref(false);
const validatedDocumentExpirationDate = ref(false);
const validatedBirthDate = ref(false);
const validatedDocumentNumber = ref(false);
const validatedState = ref(false);
const validatedProvince = ref(false);
const validatedDistrict = ref(false);

const pPositionId = ref(0);
const pProjectId = ref(0);
const personIdNew = ref(0);

const props = defineProps({
  ppPerson: {
    type: Object,
    required: true
  },
  ppKey: {
    type: Number,
    required: true
  },
  ppPositionId: {
    type: Number,
    required: true
  },
  ppProjectId: {
    type: Number,
    required: true
  }
});

const emit = defineEmits(['closeModal']);

//#region Eventos
onMounted(() => {
    Initialize();
    CambiarEspanol();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadPerson();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadProfession();
    LoadState();
    LoadVisaSitation();
    LoadSingleWorkCertificateType();
    LoadStatusPerson();
    LoadDocumentType();
    LoadRecordType();
    LoadPerson();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
}

const LoadPerson = async() => {
    oPerson.value = props.ppPerson;
    oPerson.value.birthdate = Utilitario.formatoDate(props.ppPerson.birthdate);
    oPerson.value.startDateWorkExperience = Utilitario.formatoDate(props.ppPerson.startDateWorkExperience);
    oPerson.value.visaExpirationDate = Utilitario.formatoDate(props.ppPerson.visaExpirationDate);
    oPerson.value.passportExpirationDate = Utilitario.formatoDate(props.ppPerson.passportExpirationDate);
    oPerson.value.documentExpirationDate = Utilitario.formatoDate(props.ppPerson.documentExpirationDate);
    
    oPerson.value.visaSituationId = props.ppPerson.visaSituation ? props.ppPerson.visaSituation?.visaSituationId : 1;
    oPerson.value.stateId = props.ppPerson.state?.stateId;
    if(props.ppPerson.state){
        LoadProvince();
    }    
    oPerson.value.provinceId = props.ppPerson.province?.provinceId;
    oPerson.value.statusPersonId = props.ppPerson.statusPerson ? props.ppPerson.statusPerson?.statusPersonId : 1;
    oPerson.value.singleWorkCertificateTypeId = props.ppPerson.singleWorkCertificateType?.singleWorkCertificateTypeId;
    oPerson.value.recordTypeId = props.ppPerson.recordType?.recordTypeId;
    oPerson.value.documentTypeId = props.ppPerson.documentType ? props.ppPerson.documentType?.documentTypeId : 1;
    oPerson.value.professionId = props.ppPerson.profession?.professionId;
    if(props.ppPerson.profession){
        LoadSpecialty();
    }    
    oPerson.value.specialtyId = props.ppPerson.specialty?.specialtyId;

    oPerson.value.shirtSizeId = props.ppPerson.shirtSize;
    oPerson.value.pantsSize = props.ppPerson.pantsSize;
    oPerson.value.shoesSize = props.ppPerson.shoesSize;
    pPositionId.value = props.ppPositionId;
    pProjectId.value = props.ppProjectId;
}

const LoadState = async() => {
    bCargando.value = true;
    const countryId = userStore.enterprise.countryId;
    const response = await countryService.GetState(countryId).then();
    if(response.code == 200){
        oListState.value = response.data;
        bCargando.value = false;
    }
}

const LoadProvince = async() => {
    bCargando.value = true;
    const response = await countryService.GetProvince(oPerson.value.stateId).then();
    if(response.code == 200){
        oListProvince.value = response.data;
        bCargando.value = false;
    }
}

const LoadVisaSitation = async() => {
    bCargando.value = true;
    const response = await commonService.GetVisaSituation().then();
    if(response.code == 200){
        oListVisaSituation.value = response.data;
        bCargando.value = false;
    }
}

const LoadSingleWorkCertificateType = async() => {
    bCargando.value = true;
    const response = await commonService.GetSingleWorkCertificateType().then();
    if(response.code == 200){
        oListSingleWorkCertificateType.value = response.data;
        bCargando.value = false;
    }
}

const LoadProfession = async() => {
    bCargando.value = true;
    const response = await professionService.GetProfessionService().then();
    if(response.code == 200){
        oListProfession.value = response.data;
        bCargando.value = false;
    }
}

const LoadSpecialty = async() => {
    bCargando.value = true;
    const professionId = oPerson.value.professionId;
    const response = await specialtyService.GetSpecialtyService(professionId).then();
    if(response.code == 200){
        oListSpecialty.value = response.data;
        bCargando.value = false;
    }
}

const LoadStatusPerson = async() => {
    bCargando.value = true;
    const response = await personService.GetStatusPerson().then();
    if(response.code == 200){
        oListStatusPerson.value = response.data;
        bCargando.value = false;
    }
}

const LoadDocumentType = async() => {
    bCargando.value = true;
    const response = await commonService.GetDocumentType().then();
    if(response.code == 200){
        oListDocumentType.value = response.data;
        bCargando.value = false;
    }
}

const LoadRecordType = async() => {
    bCargando.value = true;
    const response = await commonService.GetRecordType().then();
    if(response.code == 200){
        oListRecord.value = response.data;
        bCargando.value = false;
    }
}

const closeModalHandler = () => {
  emit('closeModal', personIdNew.value);
}

const SavePerson = async() => {
    if(await ValidatePerson()){
       await CreateUpdatePerson();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidatePerson = async() => {
    const { ...model } = oPerson.value;
    const valid = ref(false);

    if(!model?.firstName){
        validatedFirstName.value = true;
        valid.value = true;
    }
    else{
        validatedFirstName.value = false;
    }

    if(!model?.lastName){
        validatedLastName.value = true;
        valid.value = true;
    }
    else{
        validatedLastName.value = false;
    }
    
    if(!model?.documentExpirationDate){
        validatedDocumentExpirationDate.value = true;
        valid.value = true;
    }
    else{
        validatedDocumentExpirationDate.value = false;
    }

    if(!model?.birthdate){
        validatedBirthDate.value = true;
        valid.value = true;
    }
    else{
        validatedBirthDate.value = false;
    }

    if(!model?.documentNumber){
        validatedDocumentNumber.value = true;
        valid.value = true;
    }
    else{
        validatedDocumentNumber.value = false;
    }

    if(!model?.stateId){
        validatedState.value = true;
        valid.value = true;
    }
    else{
        validatedState.value = false;
    }

    if(!model?.provinceId){
        validatedProvince.value = true;
        valid.value = true;
    }
    else{
        validatedProvince.value = false;
    }

    if(!model?.district){
        validatedDistrict.value = true;
        valid.value = true;
    }
    else{
        validatedDistrict.value = false;
    }

    return !valid.value;
}

const CreateUpdatePerson = async() => {
    const { ...model } = oPerson.value;
    console.log('create')
    
    const request = {
        ...model,
        documentType: {
            documentTypeId: model.documentTypeId,
            name: ''
        },
        state: {
            stateId: model.stateId,
            name: ''
        },
        province: {
            provinceId: model.provinceId,
            name: ''
        },
        singleWorkCertificateType: {
            singleWorkCertificateTypeId: model.singleWorkCertificateTypeId || 0,
            name: ''
        },
        recordType: {
            recordTypeId: model.recordTypeId || 0,
            name: ''
        },
        statusPerson: {
            statusPersonId: model.statusPersonId || 0,
            name: ''
        },
        profession: {
            professionId: model.professionId || 0,
            name: ''
        },
        specialty: {
            specialtyId: model.specialtyId || 0,
            name: ''
        },
        visaSituation: {
            visaSituationId: model.visaSituationId,
            name: ''
        },
        jobPositionId: pPositionId.value,
        shirtSize: model.shirtSizeId,
        creationUser: userStore.user.idUser
    };
    
    confirm.require({
      message: `¿Está seguro de guardar la persona?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await personService.CreateUpdatePersonService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó la persona correctamente`,
                    accept: async() => {
                        bCargando.value = false;
                        /*const projectId = encodeURIComponent(Crypto.Encriptar(pProjectId.value));
                        const positionId = encodeURIComponent(Crypto.Encriptar(pPositionId.value));
                        const personId = encodeURIComponent(Crypto.Encriptar(response.data.personId));
                        router.push({ path: `/portal/Accreditation/Personal/${projectId}/${positionId}/${personId}` });*/
                        personIdNew.value = response.data.personId;
                        closeModalHandler();
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Persona:`, error);
        }
      }});
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
