<template>
<div class="card mb-2 pb-3 pt-3">
    <Toolbar class="mb-0 p-0" style="background-color: white; border: none;">
        <template #start>
            <div>
                <h2 class="mb-0">{{ oEnterprise.name }}</h2>
                <p>Creación: {{ Utilitario.formatoFecha(oEnterprise.starDateOperations, "dd/MM/yyyy") }}</p>
            </div>
        </template>
        <template #end>
            <Button label="Detalle de empresa" saverity="secondary" class="mr-2" icon="pi pi-info-circle" @click="modalDetalleEmpresa = true" />
        </template>
    </Toolbar>
</div>
<div class="card pt-3">
    <div class="card-container">

        <DataTable ref="dtProject" 
            :value="oListProject"
            dataKey="projectId"
            :rowHover="true"
            :loading="bCargando"
            :filters="filtros"
            class="p-datatable-sm"
            >

            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center pb-3">
                    <h5 class="m-0">Proyectos</h5>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <Button label="Nuevo proyecto" icon="pi pi-plus" class="p-button-success mr-2" @click="OpenServiceNew" />
                        <!--<InputText v-model="filtros['global'].value" placeholder="Buscar..." />-->
                    </span>
                </div>
            </template>

            <Column style="text-align: center;" headerStyle="min-width:5rem;">
                <template #body="slotProps">
                    <Button icon="pi pi-eye" v-tooltip.top="'Ver Detalle'" class="p-button-rounded p-button-secondary mr-2" 
                        @click="ViewServiceGrafics(slotProps.data)"/>
                </template>
            </Column>
            <Column field="code" header="Código"></Column>
            <Column field="name" header="Nombre" :sortable="true">
                <template #body="slotProps">
                    <Button :label="slotProps.data.name" link @click="ViewServiceGrafics(slotProps.data)" />
                </template>
            </Column>
            <Column field="startDate" header="Fecha Inicio">
                <template #body="slotProps">
                    {{ Utilitario.formatoFecha(slotProps.data.startDate, "dd/MM/yyyy") }}
                </template>
            </Column>
            <Column field="endDate" header="Fecha Vigencia">
                <template #body="slotProps">
                    {{ Utilitario.formatoFecha(slotProps.data.endDate, "dd/MM/yyyy") }}
                </template>
            </Column>
            <Column field="advance" header="Progreso %" class="text-center" style="width: 300px;">
                <template #body="slotProps">
                    <MeterGroup :value="slotProps.data.advance" />
                </template>
            </Column>
            <Column field="status" header="Estado">
                <template #body="slotProps">
                    <div class="centered-content">
                        <Tag v-if="slotProps.data.status == 1" value="Vigente" severity="success" />
                        <Tag v-if="slotProps.data.status == 2" value="No vigente" severity="danger" />
                        <Tag v-if="slotProps.data.status == 3" value="No aplica" severity="secondary" />
                    </div>
                </template>
            </Column>
        </DataTable>     
        <!--<div class="bg-auto bg-center  bg-no-repeat bg-blue-500 border-round h-30rem w-full" style="background-image: url('layout/images/fondo_login.jpg');"></div>-->
    </div>
</div>

<Dialog v-model:visible="modalDetalleEmpresa" modal header="Yanacocha" :style="{ width: '25rem' }">
    <span class="p-text-secondary block mb-5">Información de empresa.</span>
    <div class="flex align-items-center gap-3 mb-3">
        <label for="username" class="font-semibold w-6rem">R.U.C.:</label>
        <InputText id="username" class="flex-auto" autocomplete="off" :value="oEnterprise.ruc" readonly />
    </div>
    <div class="flex align-items-center gap-3 mb-5">
        <label for="email" class="font-semibold w-6rem">Ubicación</label>
        <InputText id="username" class="flex-auto" autocomplete="off" :value="oEnterprise.address" readonly />
    </div>
    <div class="flex justify-content-end gap-2">
        <Button type="button" label="Cerrar" severity="secondary" @click="modalDetalleEmpresa = false"></Button>
    </div>
</Dialog>
</template>

<script setup>
import EnterpriseService from '@/service/EnterpriseService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

const enterpriseService = new EnterpriseService();

let userStore = JSON.parse(store.state.datauser);

const modalDetalleEmpresa = ref(false);
const bCargando = ref(false);
const filtros = ref({});

const colors = ['#34d399', '#FF5733', '#3357FF', '#F1C40F'];
const oEnterprise = ref({});
const oListProject = ref([]);

const oListServiceData = ref([/*
    { ServiceId : 1, Code: '0001', Name: 'Cambios de revestimiento de molinos', Value: 15, Status: 1, ExpirateDate: '10/12/2025', StartDate: '01/02/2024' },
    { ServiceId : 2, Code: '0002', Name: 'Servicio de mantenimiento 2', Value: 75, Status: 1, ExpirateDate: '10/12/2025', StartDate: '01/02/2024' },
    { ServiceId : 3, Code: '0003', Name: 'Servicio de mantenimiento 3', Value: 80, Status: 2, ExpirateDate: '10/12/2025', StartDate: '01/02/2024' },
    { ServiceId : 4, Code: '0004', Name: 'Servicio de mantenimiento 4', Value: 35, Status: 3, ExpirateDate: '10/12/2025', StartDate: '01/02/2024' },
    { ServiceId : 5, Code: '0005', Name: 'Servicio de mantenimiento 5', Value: 47, Status: 1, ExpirateDate: '10/12/2025', StartDate: '01/02/2024' },*/
]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadEnterpriseProject();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadEnterpriseProject = () => {
    const enterpriseId = userStore.enterprise.enterpriseId;
    enterpriseService.GetEnterpriseByIdService(enterpriseId).then(
        response => {
            oEnterprise.value = response.data;
            if(response.data.lstProject != null){
                oListServiceData.value = response.data.lstProject.filter(x => x.status);
                oListServiceData.value.forEach((item, index) => {
                    oListProject.value.push({
                        ...item,
                        advance: [{ label: '', color: colors[index % colors.length], value: item.progressPercentage }]
                    });
                });
            }            
        }
    );
}

const ViewServiceGrafics = (item) => {
    const projectId = encodeURIComponent(Crypto.Encriptar(item.projectId));
    router.push({ path: `/portal/Servicio/Detalle/${projectId}` });
}

const OpenServiceNew = () => {
    router.push({ path: `/portal/Servicio/Nuevo` });
}
//#endregion
</script>