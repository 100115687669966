<template>
  <div class="grid">
    <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
      <div class="card mb-0">
        <div class="field col-12 md:col-12 mb-1 pb-3">
          <h5>Reporte de personal y tipo de trabajador</h5>
        </div>

        <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
          <div class="grid">
            <!-- <div class="col-12 lg:col-6 xl:col-3">
              <div class="card mb-0 pb-0">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="block font-semibold">Cumplimiento</span>
                    <div class="text-900 font-bold text-2xl"><b>40%</b></div>
                    <div class="font-medium text-sm">4 de 10 requeridos</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-green-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-file text-green-500 text-xl"></i>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-12 lg:col-6 xl:col-4">
              <div class="card mb-0 pb-0">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <div class="text-900 font-bold text-2xl"><b>11</b></div>
                    <div class="font-medium text-sm">Planilla</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-user text-cyan-500 text-xl"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4">
              <div class="card mb-0 pb-0">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <div class="text-900 font-bold text-2xl"><b>11</b></div>
                    <div class="font-medium text-sm">Temporal</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-4">
              <div class="card mb-0 pb-0">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <div class="text-900 font-bold text-2xl"><b>0</b></div>
                    <div class="font-medium text-sm">Total</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-purple-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-user-plus text-purple-500 text-xl"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <div class="flex flex-column h-500rem">
          <div class="p-fluid p-formgrid grid mb-0 mt-0">
            <div class="field col-12 md:col-2">
              <FloatLabel>
                <Dropdown
                  :options="oListProfile"
                  v-model="oReportParams.ProfileId"
                  optionLabel="Name"
                  optionValue="ProfileId"
                  placeholder="Perfil"
                  class="w-full"
                />
                <label for="lblPerfil">Perfil</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-2">
              <FloatLabel>
                <Dropdown
                  :options="oListTypeEmployee"
                  v-model="oReportParams.TypeEmployeeId"
                  optionLabel="Name"
                  optionValue="TypeEmployeeId"
                  placeholder="Tipo trabajador"
                  class="w-full"
                />
                <label for="lblTipoTrabajador">Tipo trabajador</label>
              </FloatLabel>
            </div>
            <div class="field col-12 md:col-1">
              <Button label="Buscar" class="button-primary" />
            </div>
          </div>
        </div>

        <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
          <template #start>
            <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0"></div>
          </template>
        </Toolbar>
        <div class="m-0">
          <DataTable
            :value="oListReport"
            :rowHover="true"
            :loading="bCargando"
            class="p-datatable-sm"
          >
            <Column field="Profile" header="Perfil">
              <template #footer>
                <strong class="grid-totals">Total general</strong>
              </template>
            </Column>
            <Column field="Payroll" header="Planilla">
              <template #footer>
                <span class="grid-totals">{{ totalPayroll }}</span>
              </template>
            </Column>
            <Column field="Temporary" header="Temporal">
              <template #footer>
                <span class="grid-totals">{{ totalTemporary }}</span>
              </template>
            </Column>
            <Column field="Total" header="Total">
              <template #footer>
                <span class="grid-totals">{{ total }}</span>
              </template>
            </Column>
            <Column style="text-align: center" headerStyle="min-width:12rem;">
              <template #body="slotProps">
                <Button
                  icon="pi pi-eye"
                  v-tooltip.top="'Editar Cita'"
                  class="p-button-rounded p-button-success mr-2"
                  @click="EditarCita(slotProps.data.CitaAtencionId)"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const bCargando = ref(false);
const oReportParams = ref({
  ProfileId: 0,
  TypeEmployeeId: 0,
});

const oListProfile = ref([
  { ProfileId: 0, Name: "Todos" },
  { ProfileId: 1, Name: "Perfil 1" },
]);

const oListTypeEmployee = ref([
  { TypeEmployeeId: 0, Name: "Todos" },
  { TypeEmployeeId: 1, Name: "Perfil 1" },
]);

const oListReport = ref([
  {
    ReportId: 1,
    Profile: "Capataz Mecánico de molinos",
    Payroll: 1,
    Temporary: 1,
    Total: 0,
  },
  {
    ReportId: 2,
    Profile: "Control de calidad",
    Payroll: 1,
    Temporary: 1,
    Total: 0,
  },
  {
    ReportId: 3,
    Profile: "Gerente de servicio de molino",
    Payroll: 1,
    Temporary: 1,
    Total: 0,
  },
  {
    ReportId: 4,
    Profile: "Ing. Superviosr mecánico de molinos",
    Payroll: 1,
    Temporary: 1,
    Total: 0,
  },
  {
    ReportId: 5,
    Profile: "Control y calidad",
    Payroll: 1,
    Temporary: 1,
    Total: 0,
  },
  {
    ReportId: 6,
    Profile: "Líder mecánico de molinos",
    Payroll: 1,
    Temporary: 1,
    Total: 0,
  },
  { ReportId: 7, Profile: "Mecánico 1", Payroll: 1, Temporary: 1, Total: 0 },
  { ReportId: 8, Profile: "Mecánico 3", Payroll: 1, Temporary: 1, Total: 0 },
  { ReportId: 9, Profile: "Mecánico 4", Payroll: 1, Temporary: 1, Total: 0 },
  { ReportId: 10, Profile: "Mecánico 5", Payroll: 1, Temporary: 1, Total: 0 },
  { ReportId: 11, Profile: "Mecánico 6", Payroll: 1, Temporary: 1, Total: 0 },
]);

// Cálculo de los totales
const totalPayroll = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.Payroll, 0);
});

const totalTemporary = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.Temporary, 0);
});

const total = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.Total, 0);
});
</script>