<template>
  <div class="card mb-0">
    <div class="field col-12 md:col-12 mb-1 pb-3">
      <h5>Reporte por curso</h5>
    </div>

    <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
      <div class="grid">
        <!-- <div class="col-12 lg:col-6 xl:col-3">
              <div class="card mb-0 pb-0">
                <div class="flex justify-content-between mb-3">
                  <div>
                    <span class="block font-semibold">Cumplimiento</span>
                    <div class="text-900 font-bold text-2xl"><b>40%</b></div>
                    <div class="font-medium text-sm">4 de 10 requeridos</div>
                  </div>
                  <div
                    class="flex align-items-center justify-content-center bg-green-100 border-round"
                    style="width: 2.5rem; height: 2.5rem"
                  >
                    <i class="pi pi-file text-green-500 text-xl"></i>
                  </div>
                </div>
              </div>
            </div> -->
        <div class="col-12 lg:col-6 xl:col-4">
          <div class="card mb-0 pb-0">
            <div class="flex justify-content-between mb-3">
              <div>
                <div class="text-900 font-bold text-2xl"><b>4</b></div>
                <div class="font-medium text-sm">Cursos</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-green-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-user text-green-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-4">
          <div class="card mb-0 pb-0">
            <div class="flex justify-content-between mb-3">
              <div>
                <div class="text-900 font-bold text-2xl"><b>4</b></div>
                <div class="font-medium text-sm">Registros</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-red-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-user-plus text-red-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-4">
          <div class="card mb-0 pb-0">
            <div class="flex justify-content-between mb-3">
              <div>
                <div class="text-900 font-bold text-2xl"><b>0</b></div>
                <div class="font-medium text-sm">Total</div>
              </div>
              <div
                class="flex align-items-center justify-content-center bg-purple-100 border-round"
                style="width: 2.5rem; height: 2.5rem"
              >
                <i class="pi pi-user-plus text-purple-500 text-xl"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div class="flex flex-column h-500rem">
      <div class="p-fluid p-formgrid grid mb-0 mt-0">
        <div class="field col-12 md:col-2">
          <FloatLabel>
            <Dropdown
              :options="oListCourse"
              v-model="oReportParams.CourseId"
              optionLabel="Name"
              optionValue="CourseId"
              placeholder="Perfil"
              class="w-full"
            />
            <label for="lblPerfil">Curso</label>
          </FloatLabel>
        </div>
        <div class="field col-12 md:col-2">
          <FloatLabel>
            <Dropdown
              :options="oListAptitud"
              v-model="oReportParams.AptitudId"
              optionLabel="Name"
              optionValue="AptitudId"
              placeholder="Perfil"
              class="w-full"
            />
            <label for="lblPerfil">Aptitud EMO</label>
          </FloatLabel>
        </div>
        <div class="field col-12 md:col-2">
          <FloatLabel>
            <Dropdown
              :options="oListTypeEmployee"
              v-model="oReportParams.TypeEmployeeId"
              optionLabel="Name"
              optionValue="TypeEmployeeId"
              placeholder="Tipo trabajador"
              class="w-full"
            />
            <label for="lblTipoTrabajador">Tipo trabajador</label>
          </FloatLabel>
        </div>
        <div class="field col-12 md:col-2">
          <FloatLabel>
            <Dropdown
              :options="oListCondition"
              v-model="oReportParams.ConditionId"
              optionLabel="Name"
              optionValue="ConditionId"
              placeholder="Tipo trabajador"
              class="w-full"
            />
            <label for="lblTipoTrabajador">Condición mina</label>
          </FloatLabel>
        </div>
        <div class="field col-12 md:col-1">
          <Button label="Buscar" class="button-primary" />
        </div>
      </div>
    </div>

    <Toolbar class="mb-4 p-0" style="background-color: white; border: none">
      <template #start>
        <div class="p-fluid p-formgrid grid m-5 mb-0 mt-0"></div>
      </template>
    </Toolbar>
    <div class="m-0">
      <DataTable
        :value="oListReport"
        :rowHover="true"
        :loading="bCargando"
        class="p-datatable-sm"
      >
        <Column field="Profile" header="Estatus">
          <template #footer>
            <strong class="grid-totals">Total</strong>
          </template>
        </Column>
        <Column field="Total" header="Total">
          <template #footer>
            <span class="grid-totals">{{ total }}</span>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";

const bCargando = ref(false);

const oReportParams = ref({
  CourseId: 1,
  AptitudId: 0,
  TypeEmployeeId: 0,
  ConditionId: 0,
});

const oListCourse = ref([
  { CourseId: 1, Name: "ABE" },
  { CourseId: 2, Name: "Perfil 1" },
]);

const oListAptitud = ref([
  { AptitudId: 0, Name: "Todos" },
  { AptitudId: 1, Name: "Perfil 1" },
]);

const oListTypeEmployee = ref([
  { TypeEmployeeId: 0, Name: "Todos" },
  { TypeEmployeeId: 1, Name: "Tipo trabajador 1" },
]);

const oListCondition = ref([
  { ConditionId: 0, Name: "Todos" },
  { ConditionId: 1, Name: "Condición 1" },
]);

const oListReport = ref([
  { ReportId: 0, Profile: "Vigente", Total: 0 },
  { ReportId: 1, Profile: "Pendiente", Total: 0 },
  { ReportId: 2, Profile: "Vencido", Total: 0 },
  { ReportId: 3, Profile: "(en blanco)", Total: 0 },
]);

// Cálculo de los totales
const totalPayroll = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.Payroll, 0);
});

const totalTemporary = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.Temporary, 0);
});

const total = computed(() => {
  return oListReport.value.reduce((sum, item) => sum + item.Total, 0);
});
</script>