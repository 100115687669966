export default class ProfessionService {
    async GetProfessionService() {
        const data = JSON.parse(localStorage.getItem('datauser'));
        const response = await fetch(process.env.VUE_APP_API_IOT + `/v1/Profession`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${data.token}`, 
                'Content-Type': 'application/json' 
            }
        });

        return await response.json();
    }
}