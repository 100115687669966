<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-0 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <Button icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                            <div class="ml-3">
                                <h3 style="margin: 0;">{{ oProject.projectName }}</h3>
                                <p style="margin: 0;">{{ oProject.projectCode }} - Fecha de inicio: {{ Utilitario.formatoFecha(oProject.projectStartDate, "dd/MM/yyyy") }}</p>
                            </div>
                        </div>
                    </template>
                </Toolbar>
            </div>
        </div>
        <div class="col-12 lg:col-12 xl:col-12">
            <div class="card mb-0">
                <TabView>
                    <TabPanel header="ACREDITACIÓN">
                        <div class="col-12 lg:col-12 xl:col-12 m-0 p-0">
                            <div class="grid">
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>{{ oProject.percentageRequired }}%</b></div>
                                                <div class="font-medium text-sm">{{ oProject.coincidences }} de {{ oProject.totalRequired }} requeridos</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-green-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-file text-green-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>{{ oProject.totalRequired }}</b></div>
                                                <div class="font-medium text-sm">Requeridos</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user text-cyan-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>{{ oProject.totalAccredited }}</b></div>
                                                <div class="font-medium text-sm">Acreditado</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div class="col-12 lg:col-6 xl:col-3">
                                    <div class="card mb-0 pb-0">
                                        <div class="flex justify-content-between mb-3">
                                            <div>
                                                <div class="text-900 font-bold text-2xl"><b>{{ oProject.coincidences }}</b></div>
                                                <div class="font-medium text-sm">Coincidencias</div>
                                            </div>
                                            <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                                                style="width: 2.5rem; height: 2.5rem">
                                                <i class="pi pi-user-plus text-purple-500 text-xl"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>               
                            </div>
                        </div>
                        <div class="col-12 lg:col-6 xl:col-12 m-0 p-0">
                            <div class="card">
                                <DataTable ref="dtPosition" 
                                    v-model:expandedRows="expandedRows" 
                                    :value="oProject.jobPositions"
                                    dataKey="jobPositionId"
                                    :rowHover="true"
                                    :loading="bCargando"
                                    
                                    class="p-datatable-sm"
                                    >
                                    
                                    <Column expander style="width: 5rem" />
                                    <Column field="name" header="Puesto"></Column>
                                    <Column field="requiredQuantity" header="Requerimiento"></Column>
                                    <Column field="experienceTime" header="Años Exp."></Column>
                                    <Column field="status" header="Estado" bodyClass="text-center">
                                        <template #body="slotProps">
                                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.status, 'pi-times-circle text-red-500': !slotProps.data.status }"></i>
                                        </template>
                                    </Column>

                                    <template #expansion="slotProps">
                                        <Button label="Agregar" icon="pi pi-plus" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                                            @click="openFindPersonal(slotProps.data)" />
                                        <div class="p-4" v-if="slotProps.data.persons.length > 0">
                                            <DataTable :value="slotProps.data.persons" :filters="filtros">
                                                <template #header>
                                                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                                        <h5 class="m-0">Personal seleccionado</h5>
                                                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                                                            <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                                                        </span>
                                                    </div>
                                                </template>

                                                <Column style="text-align: center;" headerStyle="min-width:5rem;">
                                                    <template #body="slotPropsDetail">
                                                        <Button icon="pi pi-eye" v-tooltip.top="'Ver Detalle'" class="p-button-rounded p-button-secondary mr-2" 
                                                                @click="ViewAccreditationPersonal(slotPropsDetail.data, slotProps.data)" />
                                                    </template>
                                                </Column>
                                                <Column field="Index" header="#"></Column>
                                                <Column field="documentNumber" header="DNI" >
                                                    <template #body="slotPropsDetail">
                                                        <span v-tooltip.top="`Fecha Vencimiento:\n${ slotPropsDetail.data.ExpirationDate }`">{{ slotPropsDetail.data.documentNumber }}</span>
                                                    </template>
                                                </Column>
                                                <Column field="FullName" header="Nombre y Apellido">
                                                    <template #body="slotPropsDetail">
                                                        <Button :label="slotPropsDetail.data.personFullName" link @click="ViewAccreditationPersonal(slotPropsDetail.data, slotProps.data)" />
                                                    </template>
                                                </Column>
                                                <Column field="typeOfContract" header="Tipo Contrato"></Column>
                                                <Column field="mineCondition" header="Condición de mina"></Column>
                                                <Column field="DocumentNumber" header="Doc. Pers." bodyClass="text-center">
                                                    <template #body="slotProps">
                                                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.PersonalDocument, 'pi-times-circle text-red-500': !slotProps.data.PersonalDocument }"
                                                        aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.DocumentsPersonal)"></i>
                                                    </template>
                                                </Column>
                                                <Column field="DocumentNumber" header="Cursos" bodyClass="text-center">
                                                    <template #body="slotProps">
                                                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Courses, 'pi-times-circle text-red-500': !slotProps.data.Courses }"
                                                        aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.DocumentsCourses)"></i>
                                                    </template>
                                                </Column>
                                                <Column field="Status" header="EMOs" bodyClass="text-center">
                                                    <template #body="slotProps">
                                                        <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Emos, 'pi-times-circle text-red-500': !slotProps.data.Emos }"
                                                        aria-haspopup="true" aria-controls="overlay_panel" @click="toggle($event, slotProps.data.DocumentsEmos)"></i>
                                                    </template>
                                                </Column>
                                                <Column field="statusPersonName" header="Estado" bodyClass="text-center">
                                                    <template #body="slotPropsDet">
                                                        <div class="centered-content">
                                                            <Tag v-if="slotPropsDet.data.statusPersonName=='Disponible'" value="Disponible" severity="success" />
                                                            <Tag v-if="slotPropsDet.data.statusPersonName=='Acreditado'" value="Acreditado" severity="info" />
                                                            <Tag v-if="slotPropsDet.data.statusPersonName=='No Disponible'" value="No disponible" severity="secondary" />
                                                            <Tag v-if="slotPropsDet.data.statusPersonName=='Vigente'" value="Vigente" severity="warning" />
                                                        </div>
                                                    </template>
                                                </Column>
                                            </DataTable>
                                        </div>
                                    </template>                                    
                                </DataTable>
                            </div>
                            
                            <OverlayPanel ref="op" appendTo="body">
                                <DataTable :value="documents" selectionMode="single" >
                                    <Column field="Name" header="Documento" style="min-width: 12rem"></Column>
                                    <Column field="Status" header="Estado" bodyClass="text-center">
                                        <template #body="slotProps">
                                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.Status, 'pi-times-circle text-red-500': !slotProps.data.Status }"></i>
                                        </template>
                                    </Column>
                                </DataTable>
                            </OverlayPanel>

                        </div>
                    </TabPanel>
                    <TabPanel header="GASTOS DE MOVILIZACIÓN">
                        <AccreditationCoasts />
                    </TabPanel>
                </TabView>
            </div>
        </div>
        
        <Dialog v-model:visible="modalSearchPersonal" modal header="Buscar personal" :style="{ width: '70rem' }">
            <SearchPersonal @closeModal="closeModal" @sendList="AddPersonal" :ppProjectId="Number(projectIdEdit)" :ppKey="Number(pKey)" :ppJobPositionId="Number(positiojJobSelected)" :ppJobPositionName="positionJobNameSelected" /> 
        </Dialog>
    </div>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SearchPersonal from '../Personal/SearchPersonal.vue';
import AccreditationCoasts from '../Accreditation/AccreditationCoasts.vue';
import ProjectService from '@/service/ProjectService';
import Crypto from '@/utilitarios/Crypto';
import Utilitario from '@/utilitarios/Utilitario';

const router = useRouter();
const route = useRoute();

const projectService = new ProjectService();

const filtros = ref({});

const oProject = ref({
    projectId: 0,
    projectCode: '',
    projectName: '',
    projectStartDate: '',
    percentageRequired: 0.0,
    totalRequired: 0,
    coincidences: 0,
    totalAccredited: 0
});

const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListPersonalSelected = ref([]);
const expandedRows = ref({});
const op = ref();
const documents = ref([]);

const projectIdEdit = ref(0);
const pKey = ref(0);
const positiojJobSelected = ref(0);
const positionJobNameSelected = ref('');

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadAccreditation();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const IrAtras = () => {
    router.push({ path: `/portal` });
}

const closeModal = () => {
    modalSearchPersonal.value = false;
    LoadAccreditation();
}


const LoadAccreditation = async() => {
    bCargando.value = true;
    projectIdEdit.value = route.params.id ? Crypto.Desencriptar(route.params.id) : 0;
    const response = await projectService.GetProjectAccreditationService(projectIdEdit.value).then();
    if(response.code == 200){
        oProject.value = response.data;
        bCargando.value = false;
        pKey.value++;
    }
    /*oListPosition.value = [
        {
            PositionId: 1,
            Name: 'Mecanico 1',
            Quantity: 10,
            Status: true,
            Personal: [
                {
                    Index: 1,
                    PersonId: 1,
                    DocumentNumber: '74125526',
                    ExpirationDate: '2024-10-01',
                    FullName: 'José Ruiz',
                    TypeEmployee: 'Temporal',
                    MinCondition: 'Nuevo',
                    Status: 'Acreditado',
                    PersonalDocument: true,
                    Courses: true,
                    Emos: true,
                    DocumentsPersonal: [
                        {
                            Name: 'CV 2024 - 0.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 0.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 0.pdf',
                            Status: true
                        }
                    ],            
                    DocumentsCourses: [
                        {
                            Name: 'CV 2024 - 00.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 00.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 00.pdf',
                            Status: true
                        }
                    ],
                    DocumentsEmos: [
                        {
                            Name: 'CV 2024.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven.pdf',
                            Status: true
                        }
                    ]
                },
                {
                    Index: 2,
                    PersonId: 3,
                    DocumentNumber: '70005526',
                    ExpirationDate: '2024-09-01',
                    FullName: 'David Ruiz',
                    TypeEmployee: 'Planilla',
                    MinCondition: 'Reingresante',
                    Status: 'Disponible',
                    PersonalDocument: true,
                    Courses: true,
                    Emos: false,
                    DocumentsPersonal: [
                        {
                            Name: 'CV 2024 - 10.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 10.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 10.pdf',
                            Status: true
                        }
                    ],            
                    DocumentsCourses: [
                        {
                            Name: 'CV 2024 - 100.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 100.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 100.pdf',
                            Status: true
                        }
                    ],
                    DocumentsEmos: [
                        {
                            Name: 'CV 2024 20.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja 20.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven 20.pdf',
                            Status: false
                        }
                    ]
                },
                {
                    Index: 3,
                    PersonId: 3,
                    DocumentNumber: '70005526',
                    ExpirationDate: '2024-09-01',
                    FullName: 'Martín Torres',
                    TypeEmployee: 'Planilla',
                    MinCondition: 'Reingresante',
                    Status: 'No disponible',
                    PersonalDocument: false,
                    Courses: false,
                    Emos: false
                },
                {
                    Index: 4,
                    PersonId: 3,
                    DocumentNumber: '70005526',
                    ExpirationDate: '2024-09-01',
                    FullName: 'Carlos Castro',
                    TypeEmployee: 'Planilla',
                    MinCondition: 'Reingresante',
                    Status: 'Vigente',
                    PersonalDocument: false,
                    Courses: false,
                    Emos: false
                },
                {
                    Index: 5,
                    PersonId: 3,
                    DocumentNumber: '70005526',
                    ExpirationDate: '2024-09-01',
                    FullName: 'Edgar Quiroz',
                    TypeEmployee: 'Planilla',
                    MinCondition: 'Reingresante',
                    Status: 'Acreditado',
                    PersonalDocument: true,
                    Courses: true,
                    Emos: true,
                    DocumentsPersonal: [
                        {
                            Name: 'CV 2024 - 10.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 10.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 10.pdf',
                            Status: true
                        }
                    ],            
                    DocumentsCourses: [
                        {
                            Name: 'CV 2024 - 100.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 100.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 100.pdf',
                            Status: true
                        }
                    ],
                    DocumentsEmos: [
                        {
                            Name: 'CV 2024 20.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja 20.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven 20.pdf',
                            Status: true
                        }
                    ]
                }
            ]
        },
        {
            PositionId: 2,
            Name: 'Mecanico 2',
            Quantity: 10,
            Status: false,
            Personal: [
                {
                    Index: 1,
                    PersonId: 1,
                    DocumentNumber: '74125526',
                    ExpirationDate: '2024-10-01',
                    FullName: 'José Ruiz',
                    TypeEmployee: 'Temporal',
                    MinCondition: 'Nuevo',
                    Status: 'Acreditado',
                    PersonalDocument: true,
                    Courses: true,
                    Emos: true,
                    DocumentsPersonal: [
                        {
                            Name: 'CV 2024 - 0.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 0.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 0.pdf',
                            Status: true
                        }
                    ],            
                    DocumentsCourses: [
                        {
                            Name: 'CV 2024 - 00.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 00.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 00.pdf',
                            Status: true
                        }
                    ],
                    DocumentsEmos: [
                        {
                            Name: 'CV 2024.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven.pdf',
                            Status: true
                        }
                    ]
                },
                {
                    Index: 2,
                    PersonId: 3,
                    DocumentNumber: '70005526',
                    ExpirationDate: '2024-09-01',
                    FullName: 'David Ruiz',
                    TypeEmployee: 'Planilla',
                    MinCondition: 'Reingresante',
                    Status: 'Disponible',
                    PersonalDocument: true,
                    Courses: true,
                    Emos: false,
                    DocumentsPersonal: [
                        {
                            Name: 'CV 2024 - 10.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 10.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 10.pdf',
                            Status: true
                        }
                    ],            
                    DocumentsCourses: [
                        {
                            Name: 'CV 2024 - 100.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja - 100.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven - 100.pdf',
                            Status: true
                        }
                    ],
                    DocumentsEmos: [
                        {
                            Name: 'CV 2024 20.pdf',
                            Status: true
                        },
                        {
                            Name: 'Hoja 20.pdf',
                            Status: true
                        },
                        {
                            Name: 'CertiJoven 20.pdf',
                            Status: false
                        }
                    ]
                }
            ]
        }
    ];*/
}

const toggle = (event, olista) => {
    documents.value = olista;
    op.value.toggle(event);
};

const AddPersonal = (list) => {
    console.log(list.value);
  oListPersonalSelected.value = list.value; // Asigna la lista recibida a la variable reactiva
};

const ViewAccreditationPersonal = (item, itemPrimary) => {
    const projectId = encodeURIComponent(Crypto.Encriptar(projectIdEdit.value));
    const positionId = encodeURIComponent(Crypto.Encriptar(itemPrimary.jobPositionId));
    const personId = encodeURIComponent(Crypto.Encriptar(item.personId));
    router.push({ path: `/portal/Accreditation/Personal/${projectId}/${positionId}/${personId}` });
}


const openFindPersonal = (item) => {
    positiojJobSelected.value = item.jobPositionId;
    positionJobNameSelected.value = item.name;
    modalSearchPersonal.value = true
    pKey.value++;
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
