<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Cursos</h5>
                            </div>
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtCoursesPosition" 
                    v-model:expandedRows="expandedRows" 
                    :value="oListCoursesPosition"
                    dataKey="course.courseId"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column expander style="width: 1rem" />
                    <Column style="text-align: center;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar curso`" class="p-button-secondary-outlined ml-2"
                             @click="EditCourse(slotProps.data)" :disabled="slotProps.data.isLocked" />
                        </template>
                    </Column>
                    <Column field="Course" header="Curso">
                        <template #body="slotProps">
                            {{ slotProps.data.course.name }}
                        </template>
                    </Column>
                    <Column field="mode" header="Modalidad"></Column>                    
                    <Column field="IssueDate" header="Fecha emisión">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.issueDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="VigencyDate" header="Fecha vigencia">
                        <template #body="slotProps">
                            {{ Utilitario.formatoFecha(slotProps.data.effectiveDate, 'dd/MM/yyyy') }}
                        </template>
                    </Column>
                    <Column field="note" header="Notas"></Column>  
                    <Column field="Status" header="Estado curso">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.validity" :severity="slotProps.data.validity == 'Vigente' ? 'success' : 'danger'" />
                        </template>
                    </Column>
                    <template #expansion="slotProps">
                        <div class="p-4" v-if="slotProps.data.listPreRequisites.length > 0">
                            <DataTable 
                                :value="slotProps.data.listPreRequisites" 
                                :rowHover="true" 
                                >
                                <template #header>
                                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                                        <h5 class="m-0">Pre requisitos</h5>
                                    </div>
                                </template>

                                <Column style="text-align: center;" headerStyle="min-width:3rem;">
                                    <template #body="slotPropsPre">
                                        <Button icon="pi pi-pencil" v-tooltip.top="`Editar curso`" class="p-button-secondary-outlined ml-2" @click="EditCoursePre(slotPropsPre.data, slotProps.data)" />
                                    </template>
                                </Column>
                                <Column field="Course" header="Curso">
                                    <template #body="slotPropsPre">
                                        {{ slotPropsPre.data.courseName }}
                                    </template>
                                </Column>
                                <Column field="modeName" header="Modalidad"></Column>                    
                                <Column field="IssueDate" header="Fecha emisión">
                                    <template #body="slotPropsPre">
                                        {{ Utilitario.formatoFecha(slotPropsPre.data.issueDate, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="VigencyDate" header="Fecha vigencia">
                                    <template #body="slotPropsPre">
                                        {{ Utilitario.formatoFecha(slotPropsPre.data.effectiveDate, 'dd/MM/yyyy') }}
                                    </template>
                                </Column>
                                <Column field="note" header="Notas"></Column>  
                                <Column field="Status" header="Estado curso">
                                    <template #body="slotPropsPre">
                                        <Tag :value="slotPropsPre.data.validity" :severity="slotPropsPre.data.validity == 'Vigente' ? 'success' : 'danger'" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Certificaciones</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListCertification"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column header="">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar certificación`" class="p-button-secondary-outlined ml-2" />
                            <Button icon="pi pi-times-circle" v-tooltip.top="`Eliminar certificación`" text rounded class="p-button-danger ml-2" />
                        </template>
                    </Column>
                    <Column field="Course" header="Curso" ></Column>
                    <Column field="Mode" header="Modalidad" ></Column>
                    <Column field="Status" header="Estado curso">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.Status" severity="success" />
                        </template>
                    </Column>
                    <Column field="IssueDate" header="Fecha emisión"></Column>
                    <Column field="VigencyDate" header="Fecha vigencia"></Column>
                    <Column field="CoursePreReq" header="Curso pre-requisito"></Column>
                    <Column field="StatusPreReq" header="Estado pre-requisito">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.StatusPreReq" severity="success" />
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Estado de cursos</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListCoursesStatus"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="Status" header="Estado" >
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.Status" severity="success" />
                        </template>
                    </Column>
                    <Column field="Type" header="Tipo curso"></Column>
                    <Column field="Exhibit" header="Anexo"></Column>
                    <Column field="VigencyDate" header="F.vigencia"></Column>
                    <Column field="ExhibitSigned" header="Anexo 4 firmado">
                        <template #body="slotProps">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.ExhibitSigned, 'pi-times-circle text-red-500': !slotProps.data.ExhibitSigned }"></i>
                        </template>
                    </Column>
                    <Column field="CardAccreditation" header="Tarjeta acreditación cursos especificos">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.CardAccreditation, 'pi-times-circle text-red-500': !slotProps.data.CardAccreditation }"></i>
                            </div>
                        </template>
                    </Column>
                    <Column field="CardNumberBlocked" header="N° tarjeta bloqueo">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag :value="slotProps.data.CardNumberBlocked" severity="success" />
                            </div>
                        </template>
                    </Column>
                    <Column field="CardBlocked" header="Tarjeta bloqueo">
                        <template #body="slotProps">
                            <i class="pi" :class="{ 'pi-check-circle text-green-500 ': slotProps.data.CardBlocked, 'pi-times-circle text-red-500': !slotProps.data.CardBlocked }"></i>
                        </template>
                    </Column>
                    <Column field="AccreditationOperators" header="Acreditación operadores">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag :value="slotProps.data.AccreditationOperators" severity="success" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Personal específico(definir perfiles)</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-plus" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListLicenses"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column header="">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="`Editar licencia`" class="p-button-secondary-outlined ml-2" />
                            <Button icon="pi pi-times-circle" v-tooltip.top="`Eliminar licencia`" text rounded class="p-button-danger ml-2" />
                        </template>
                    </Column>
                    <Column field="License" header="Licencia" ></Column>
                    <Column field="AccreditaarionDrivers" header="Acreditación conductores"></Column>
                    <Column field="TypeLicense" header="Tipo licencia"></Column>
                    <Column field="VigencyDate" header="Fecha Vencimiento"></Column>
                </DataTable>

                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">                        
                        <div class="field col-12 md:col-12 pt-5 mt-5">
                            <FloatLabel>
                                <Textarea id="txtReferencia" rows="2" cols="30" />
                                <label for="lblReferencia">Observaciones</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>

            </div>
            <DocumentsAccreditation :items="oListDocumentsPersonal" />
        </div>

    </div>


    <Dialog v-model:visible="modalManagmentCourse" modal :header="modalManagmentCourseText" :style="{ width: '45rem' }">
        <div class="grid mt-3">
            <div class="col-12 lg:col-6 xl:col-12">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-6">
                            <FloatLabel>
                                <Calendar
                                    showIcon
                                    iconDisplay="input"
                                    inputId="dtpFechaFin"
                                    dateFormat="dd/mm/yy"
                                    v-model="oCoursePosition.issueDate"
                                    :class="{ 'p-invalid' : validatedIssueDate }"
                                    />
                                <label for="lblFechaFin">Fecha emisión</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-6"></div>
                        <div class="field col-12 md:col-12">
                            <FloatLabel>
                                <Textarea id="txtDescription" rows="2" cols="30" v-model="oCoursePosition.note" />
                                <label for="lblDescription">Notas</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid">        
            <div class="col-12 lg:col-6 xl:col-12">
                <Divider layout="horizontal" class="mt-0 pt-0" />
                <div class="flex justify-content-start">
                    <Button label="Cancelar" class="ml-2" severity="secondary" @click="modalManagmentCourse = false"></Button>
                    <Button label="Guardar"  severity="primary" iconPos="right" @click="SaveCoursePosition()" :loading="bCargando" />
                </div>
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import PersonService from '@/service/PersonService';
import Utilitario from '@/utilitarios/Utilitario';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import { onBeforeMount, onMounted, ref, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const toast = useToast();
const store = useStore();
const confirm = useConfirm();

const personService = new PersonService();

const userStore = JSON.parse(store.state.datauser);

const filtros = ref({});
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListDocumentsPersonal = ref([]);
const oListCoursesStatus = ref([]);
const oListCoursesPosition = ref([]);
const oListCertification = ref([]);
const oListLicenses = ref([]);

const expandedRows = ref({});

const pPersonId = ref(0);
const pPositionId = ref(0);
const modalManagmentCourse = ref(false);
const modalManagmentCourseText = ref('');
const oCoursePosition = ref({});
const validatedIssueDate = ref(false);

const props = defineProps({
  ppKey: {
    type: Number,
    required: true
  },
  ppPositionId: {
    type: Number,
    required: true
  },
  ppPersonId: {
    type: Number,
    required: true
  }
});

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        LoadCoursesPosition();
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadCoursesPosition();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadCoursesPosition = async() => {
    bCargando.value = true;
    pPersonId.value = props.ppPersonId;
    pPositionId.value = props.ppPositionId;
    if(pPersonId.value > 0){
        const response = await personService.GetCoursesByPositionService(pPersonId.value, pPositionId.value).then();
        if(response.code == 200){
            oListCoursesPosition.value = response.data;
            bCargando.value = false;
        }
    }    
}

const EditCourse = async(item) => {
    modalManagmentCourseText.value = 'Editar Curso';
    
    oCoursePosition.value.jobPositionId = item.jobPosition.jobPositionId;
    oCoursePosition.value.courseId = item.course.courseId;
    oCoursePosition.value.issueDate = item.issueDate ? Utilitario.formatoDate(item.issueDate) : null;
    oCoursePosition.value.note = item.note;

    modalManagmentCourse.value = true;
}

const EditCoursePre = async(item, itemPrimary) => {
    modalManagmentCourseText.value = 'Editar Curso';
    
    oCoursePosition.value.jobPositionId = itemPrimary.jobPosition.jobPositionId;
    oCoursePosition.value.courseId = item.courseId;
    oCoursePosition.value.issueDate = item.issueDate ? Utilitario.formatoDate(item.issueDate) : null;
    oCoursePosition.value.note = item.note;

    modalManagmentCourse.value = true;
}

const SaveCoursePosition = async() => {
    if(await ValidateCoursePosition()){
       await CreateUpdateCoursePosition();
    }
    else{
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidateCoursePosition = async() => {
    const { ...model } = oCoursePosition.value;
    const valid = ref(false);

    if(!model?.issueDate){
        validatedIssueDate.value = true;
        valid.value = true;
    }
    else{
        validatedIssueDate.value = false;
    }

    return !valid.value;
}

const CreateUpdateCoursePosition = async() => {
    const { ...model } = oCoursePosition.value;
    
    const request = {
        ...model,
        personId: pPersonId.value,
        creationUser: userStore.user.idUser
    };
    /*
    {
  "jobPositionId": 0,
  "courseId": 0,
  "personId": 0,
  "issueDate": "2025-01-26T19:53:29.983Z",
  "creationUser": 0,
  "note": "string"
}
    */
    confirm.require({
      message: `¿Está seguro de guardar el curso?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        try{
            const response = await personService.CreateUpdateCoursePoistionService(request).then();
            if(response.code == 201){
                confirm.require({
                    group: 'headless',
                    header: 'Felicidades',
                    message: `Se guardó el curso correctamente`,
                    accept: async() => {
                        LoadCoursesPosition();
                        modalManagmentCourse.value = false;
                        bCargando.value = false;
                    }
                });
            }
        }
        catch (error) {
            bCargando.value = false;
            console.error(`Error al Guardar Escala salarial:`, error);
        }
      }});
}

//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
