<template>
    <div class="layout-wrapper">
        <app-topbar></app-topbar>
        <div class="layout-main-container">
            <div class="layout-main">

                <h4 class="text-center">Seleccionar país</h4>
                <div class="grid">
                    <div class="col-12 lg:col-6 xl:col-4" v-for="(item) in oListCountries" :key="item.countryId">
                        <Card @click="SelectedCountry(item.countryId)" class="pointer" :class="{'seleccion-card': item.countryId === selectedCountry}">
                            <template #header>
                                <div class="pt-5 pb-3" style="text-align: center;">
                                    <Avatar :image="item.image" class="mr-2" size="xlarge" shape="circle" />
                                    <p style="font-size: 13pt; padding-top: 10px;">{{ item.name }}</p>
                                </div>
                            </template>
                        </Card>
                    </div>
                </div>

                <div class="grid mt-5" v-if="oListCompany.length > 0">
                    <div class="col-12">
                        <div class="card">
                            <p>Seleccionar empresa</p>
                            <DataTable ref="dtCompany" 
                                :value="oListCompany"
                                dataKey="ProductoId"
                                :rowHover="true"
                                :loading="bCargando"
                                :filters="filtros"
                                class="p-datatable-sm"
                            >
                                <Column field="name" header="Empresa"></Column>
                                <Column field="quantityServices" header="Proyectos"></Column>
                                <Column style="text-align: center;" headerStyle="min-width:12rem;">
                                    <template #body="slotProps">
                                        <Button label="Seleccionar" severity="secondary" @click="SelectedCompany(slotProps.data)" />        
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
                
            </div>
            <app-footer></app-footer>
        </div>
        <div class="layout-mask"></div>
    </div>
</template>


<script setup>
import AppTopbar from "./../layout/AppTopbar.vue";
import AppFooter from './../layout/AppFooter.vue';
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import EnterpriseService from "@/service/EnterpriseService";
import CountryService from "@/service/CountryService";

//#region Variables
const router = useRouter();
const store = useStore();

const enterpriseService = new EnterpriseService();
const countryService = new CountryService();

const bCargando = ref(false);
const filtros = ref({});
const selectedCountry = ref(0);
const oListCountries = ref([]);

const oListCompany = ref([]);

//#endregion

//#region Eventos
onMounted(() => {
    Inicializador();
});
//#endregion

//#region Metodos
const Inicializador = () => {
    //parametro.value = Crypto.Desencriptar(route.params.id);
    LoadCountryByRole();
}

const LoadCountryByRole = () => {
    countryService.GetCountryByRole().then(
        response => {
            oListCountries.value = response.data;
        }
    );
}

const LoadEnterpriseByCountry = (countryId) => {
    enterpriseService.GetEnterpriseByCountryService(countryId).then(
        response => {
            oListCompany.value = response.data;
        }
    );
}

const SelectedCountry = (countryId) => {
    selectedCountry.value = countryId;
    LoadEnterpriseByCountry(selectedCountry.value);
}

const SelectedCompany = (company) => {
    if(company){
        const country = oListCountries.value.find(x => x.countryId == selectedCountry.value);
        const newData = {
            ...company,
            countryName: country.name,
            countryImage: country.image,
            countryId: country.countryId
        }
        
        store.commit('selectedCompany', newData);
    }
    else{
        store.commit('selectedCompany', null);
    }

    router.push({ path: `/portal` });
}
//#endregion
</script>

<style scoped>
.layout-main-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
}

.layout-main {
  width: 50%; /* Ancho del 50% */
}

</style>