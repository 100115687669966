const CryptoJS = require("crypto-js");
const key = process.env.VUE_APP_CRYPTO_KEY;

export default class Crypto {
    static Encriptar(texto) { 
        const codigo = CryptoJS.AES.encrypt(texto.toString(), key).toString();     
        return encodeURIComponent(codigo);
    }

    static Desencriptar(texto) {
        if(!texto){
            return '';
        }
        const leeCodigo = decodeURIComponent(texto.toString());
        return CryptoJS.AES.decrypt(leeCodigo, key).toString(CryptoJS.enc.Utf8);
    }

    static GenerateUniqueId() {
        const array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        return array[0].toString(16);  // Convierte el valor a hexadecimal
    }
    
}