<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="OpenCertificateNew" />
                    </template>
                </Toolbar>
                <ConfirmDialog></ConfirmDialog>
                <DataTable ref="dtCertificate" 
                    :value="oListCertificate"
                    dataKey="certificateId"
                    :rowHover="true"
                    :paginator="false" 
                    :rows="10"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Certificados</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column style="text-align: center;" headerStyle="min-width:3rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2" @click="EditCertificate(slotProps.data.certificateId)" />
                            <Button icon="pi pi-power-off" v-tooltip.top="slotProps.data.status ? 'Desactivar' : 'Activar'" 
                                    class="p-button-rounded mt-2" :class="slotProps.data.status ? 'p-button-danger' : 'p-button-secondary'" @click="UpdateStatusCertification(slotProps.data)" />
                        </template>
                    </Column>
                    <Column field="code" header="Código" :sortable="true"></Column>
                    <Column field="name" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.name }}</b><br/>
                        </template>
                    </Column>
                    
                    <Column field="status" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.status" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.status" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

  <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
</template>
<script setup>
import CertificateService from '@/service/CertificateService';
import Crypto from '@/utilitarios/Crypto';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();
const confirm = useConfirm();

const certificateService = new CertificateService();

const userStore = JSON.parse(store.state.datauser);

const bCargando = ref(false);
const filtros = ref({});

const oListCertificate = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadCertificates();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const OpenCertificateNew = () => {
    router.push({ path: `/portal/Certificado/Nuevo` });
}

const EditCertificate = (certificadoId) => {
    const id = encodeURIComponent(Crypto.Encriptar(certificadoId));
    router.push({ path: `/portal/Certificado/Editar/${id}` });
}

const LoadCertificates = async() => {
    bCargando.value = true;
    const response = await certificateService.GetCertificateService(0).then();
    if(response.code == 200){
        oListCertificate.value = response.data;
        bCargando.value = false;
    }
}

const UpdateStatusCertification = async(item) => {
    const actionName = item.status ? 'desactivar' : 'activar';
    const confirmationName = item.status ? 'desactivó' : 'activó';
    const request = {
        certificateId: item.certificateId,
        status: !item.status,
        creationUser: userStore.user.idUser
    };

    confirm.require({
      message: `¿Está seguro de ${actionName} la certicación #${item.code}?`,
      header: 'Confirmación',
      icon: 'pi pi-info-circle',
      rejectLabel: 'No',
      acceptLabel: 'Si',
      acceptClass: 'p-button-primary',
      accept: async () => {
        bCargando.value = true;
        const response = await certificateService.UpdateStatusCertificateService(request).then();
        if(response.code == 201){
            confirm.require({
                group: 'headless',
                header: 'Felicidades',
                message: `Se ${confirmationName} la certificación #${response.data.code} correctamente`,
                accept: async() => {
                    LoadCertificates();
                    bCargando.value = false;
                }
            });
        }
      }
    });
}
//#endregion
</script>