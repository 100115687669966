<template>
    <div class="grid">
        <div class="col-12 lg:col-12 xl:col-12 pb-0 pt-0">
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Estado general</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListGeneralStatus"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    <Column field="ContractType" header="Tipo contrato servicio esp adenda"></Column>
                    <Column field="ExpirationStart" header="Fecha inicio"></Column>
                    <Column field="ExpirationDate" header="Fecha vencimiento"></Column>
                    <Column field="Turn" header="Turno"></Column>
                    <Column field="State" header="Estado"></Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">EPPs</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListEPP"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="PhotocheckProcess" header="Trámite fotocheck"></Column>
                    <Column field="Photocheck" header="N° fotocheck"></Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Pólizas</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListPolicy"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="Policy" header="Póliza" ></Column>
                    <Column field="CodePlicy" header="N° póliza"></Column>
                    <Column field="CompanyPolicy" header="Empresa"></Column>
                    <Column field="ExpirationDate" header="Fecha vencimiento"></Column>
                    <Column field="StatusPolicy" header="Estado"></Column>
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Desempeño</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListPerformance"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="LastDate" header="Última fecha actualización" ></Column>
                    <Column field="EMPO" header="EMPO">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.EMPO" severity="success" />
                        </template>
                    </Column>
                    <Column field="WorkHeight" header="Trabajos altura">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.WorkHeight" severity="info" />
                        </template>
                    </Column>
                    <Column field="ConfinedSpace" header="Espacios confinados">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.ConfinedSpace" severity="info" />
                        </template>
                    </Column>
                    <Column field="HotWelding" header="Es caliente c/ soldadura">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.HotWelding" severity="info" />
                        </template>
                    </Column>
                    
                </DataTable>
            </div>
            <div class="card pt-3">
                <Toolbar class="mb-3 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <div style="display: flex; align-items: flex-start;">
                            <div>
                                <h5 style="margin: 0;">Desempeño 2</h5>
                            </div>
                        </div>
                    </template>
                    <template #end>                        
                        <div style="display: flex; align-items: flex-end;">
                            <Button label="" icon="pi pi-pencil" v-tooltip.top="`Agregar personal`" class="p-button-success ml-2" 
                                @click="modalSearchPersonal = true" />
                        </div>
                    </template>
                </Toolbar>
                <DataTable ref="dtPersonal" 
                    :value="oListAssessment"
                    dataKey="PersonId"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >
                    
                    <Column field="SupervisorFeedback" header="Feedback supervisor" ></Column>
                    <Column field="PerformanceAppraisal" header="Valoración desempeño"></Column>
                    <Column field="Category" header="Categoría">
                        <template #body="slotProps">
                            <Tag :value="slotProps.data.Category" severity="danger" />
                        </template>
                    </Column>
                    <Column field="PerformanceDate" header="Fecha desempeño"></Column>
                </DataTable>

                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">                        
                        <div class="field col-12 md:col-12 pt-5 mt-5">
                            <FloatLabel>
                                <Textarea id="txtReferencia" rows="2" cols="30" />
                                <label for="lblReferencia">Observaciones</label>
                            </FloatLabel>
                        </div>
                    </div>
                </div>

            </div>
            <DocumentsAccreditation :items="oListDocumentsPersonal" />
        </div>

    </div>
</template>

<script setup>
import DocumentsAccreditation from '@/components/Documents/DocumentsAccreditation.vue';
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const filtros = ref({});
const bCargando = ref(false);
const modalSearchPersonal = ref(false);
const oListDocumentsPersonal = ref([]);
const oListGeneralStatus = ref([]);
const oListPolicy = ref([]);
const oListPerformance = ref([]);
const oListAssessment = ref([]);

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    LoadPersonal();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const LoadPersonal = () => {

    oListGeneralStatus.value = [{
        StatusTickets:'Comprado',
        PassageType: 'Aéreo',
        ContractType: 'Servicio espec. adenda',
        Turn: 'Día',
        State: 'Firmado',
        ExpirationStart: '01/01/2024',
        ExpirationDate: '17/05/2024'
    }];

    oListPolicy.value = [
        {
            Policy: 'Póliza salud',
            CodePlicy: '455422-555',
            CompanyPolicy: 'Mapfre',
            ExpirationDate: 'definir',
            StatusPolicy: 'Vigente'
        },
        {
            Policy: 'Póliza pensión',
            CodePlicy: '455422-555',
            CompanyPolicy: 'Mapfre',
            ExpirationDate: 'definir',
            StatusPolicy: 'Vigente'
        },
        {
            Policy: 'Seguro internacional',
            CodePlicy: '455422-555',
            CompanyPolicy: 'Mapfre',
            ExpirationDate: 'definir',
            StatusPolicy: 'Vigente'
        },
        {
            Policy: 'Póliza responsabilidad civil',
            CodePlicy: '455422-555',
            CompanyPolicy: 'Mapfre',
            ExpirationDate: 'definir',
            StatusPolicy: 'Vigente'
        },
        {
            Policy: 'Póliza SCTR',
            CodePlicy: '455422-555',
            CompanyPolicy: 'Mapfre',
            ExpirationDate: 'definir',
            StatusPolicy: 'Vigente'
        },
        {
            Policy: 'Póliza accidentes personales',
            CodePlicy: '455422-555',
            CompanyPolicy: 'Mapfre',
            ExpirationDate: 'definir',
            StatusPolicy: 'Vigente'
        }
    ];

    oListPerformance.value = [
        {
            LastDate: '17/02/2024',
            EMPO: 'Si',
            WorkHeight: 'Apto',
            ConfinedSpace: 'Apto',
            HotWelding: 'Apto'
        }
    ];
    
    oListAssessment.value = [
        {
            SupervisorFeedback: 'Felipe Chum',
            PerformanceAppraisal: 'Medio',
            Category: 'No recomendado',
            PerformanceDate: '20/12/2024'
        }
    ];

    
    oListDocumentsPersonal.value = [
        {
            Index: 1,
            Document: 'TIPO CONTRATO SERVICIO ESP ADENDA',
            Url: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pdf%2FDNI.pdf?alt=media&token=39517142-aa67-4f36-a1a2-190b4f3c4bfb',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 2,
            Document: 'ESTADO SCTR',
            Url: 'https://firebasestorage.googleapis.com/v0/b/nportal-b6933.appspot.com/o/pdf%2FHoja%20de%20Vida.pdf?alt=media&token=734674ea-826f-4183-844a-6f2f3805593c',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 3,
            Document: 'N° PÓLIZA RESPONSABILIDAD CIVIL',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 4,
            Document: 'N° PÓLIZA ACCIDENTES PERSONAL',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 5,
            Document: 'DOC. SUSTENTARIO ASIGNACIÓN FAMILIAR',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 6,
            Document: 'VALORACIÓN DE DESEMPEÑO',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        },
        {
            Index: 7,
            Document: 'COMENTARIO DE DESEMPEÑO',
            Url: '',
            LastDate: '01/12/2024',
            Status: true
        }
    ];
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
