<template>
    <div class="grid">
        
        <div class="col-12 lg:col-6 xl:col-12">
            <!--<div class="card mb-1 pb-3">
                <div class="flex flex-column h-500rem">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-12 md:col-3 pb-0 mb-0">
                            <FloatLabel>
                                <Dropdown :options="oListProfession" v-model="oFilters.ProfessionId"
                                    optionLabel="Name" optionValue="ProfessionId" class="w-full" @change="LoadSpecialty()" />
                                <label for="lblProfession">Profesión</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-3 pb-0 mb-0">
                            <FloatLabel>
                                <Dropdown :options="oListSpecialty" v-model="oFilters.SpecialtyId"
                                    optionLabel="Name" optionValue="SpecialtyId" class="w-full" @change="ClearReport()" />
                                <label for="lblSpecialty">Especialidad</label>
                            </FloatLabel>
                        </div>
                        <div class="field col-12 md:col-2 mb-0">
                            <Button label="Aplicar" icon="pi pi-search" class="p-button-success mr-2" @click="LoadPersonal()" />
                        </div>
                        <div class="field col-12 md:col-2 mb-0"></div>
                    </div>
                </div>
            </div>-->
            <div class="card mt-0 pt-2">
                <div class="field col-12 md:col-2 mb-0 mt-0">
                    <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NewPersonal()" />
                </div>
                <DataTable ref="dtPersonal" 
                    :value="oListPersonal"
                    dataKey="personId"
                    v-model:selection="oListSelected"
                    :rowHover="true"
                    :loading="bCargando"
                    :filters="filtros"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Seleccione un colaborador para agregarlo</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." />
                            </span>
                        </div>
                    </template>

                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column field="documentNumber" header="DNI" >
                        <template #body="slotProps">
                            {{ slotProps.data.documentNumber }}
                        </template>
                    </Column>
                    <Column field="fullName" header="Nombre y Apellido"></Column>
                    <Column field="professionName" header="Profesión"></Column>
                    <Column field="specialtyName" header="Especialidad"></Column>
                    <Column field="experience" header="Experiencia"></Column>
                    <Column field="typeOfContractName" header="Tipo Contrato"></Column>
                    <Column field="typeOfEmployeeOnboardingName" header="Condición de mina"></Column>
                </DataTable>
            </div>
            <div class="flex justify-end gap-2">
                <Button type="button" label="Cancelar" severity="secondary" @click="closeModalHandler"></Button>
                <Button type="button" label="Aceptar" class="p-button-primary mr-2" @click="AddPersonal()"></Button>
            </div>
        </div>

    </div>

 <ConfirmDialog group="headless">
    <template #container="{ message, acceptCallback }">
        <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
            <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                <i class="pi pi-check text-5xl"></i>
            </div>
            <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
            <p class="mb-0">{{ message.message }}</p>
            <div class="flex align-items-center gap-2 mt-4">
                <Button label="Aceptar" @click="acceptCallback"></Button>
            </div>
        </div>
    </template>
  </ConfirmDialog>
  <ConfirmDialog group="msgWarning">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-warning inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-exclamation-triangle text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { defineEmits, defineProps } from 'vue';
import { useConfirm } from 'primevue/useconfirm';
import PersonService from '@/service/PersonService';
import Crypto from '@/utilitarios/Crypto';
import { useStore } from 'vuex';

const router = useRouter();
const confirm = useConfirm();
const store = useStore();

const personService = new PersonService();

const userStore = JSON.parse(store.state.datauser);

const filtros = ref({});
const oListPersonal = ref([]);
const bCargando = ref(false);
const oListProfession = ref([]);
const oListSpecialty = ref([]);
const oListSelected = ref([]);
const oFilters = ref({
    ProfessionId: 0,
    SpecialtyId: 0
});
const positionJobIdSelected = ref(0);
const projectIdSelected = ref(0);
const positionJobNameSelected = ref('');

const emit = defineEmits(['sendList', 'closeModal']);

const props = defineProps({
  ppProjectId: {
    type: Number,
    required: true
  },
  ppJobPositionId: {
    type: Number,
    required: true
  },
  ppJobPositionName: {
    type: String,
    required: true
  },
  ppKey: {
    type: Number,
    required: true
  }
});

//#region Eventos
onMounted(() => {
    Initialize();
});

onBeforeMount(() => {
    InitializeFilters();
});

watch(() => props.ppKey, (newVal, oldVal) => {
    if(newVal !== oldVal){
        positionJobIdSelected.value = props.ppJobPositionId;
        projectIdSelected.value = props.ppProjectId;
        positionJobNameSelected.value = props.ppJobPositionName;
    }
});
//#endregion

//#region Metodos
const Initialize = () => {
    InitializeFilters();
    projectIdSelected.value = props.ppProjectId;
    positionJobIdSelected.value = props.ppJobPositionId;
    positionJobNameSelected.value = props.ppJobPositionName;
    LoadPersonal();
    //LoadProfession();
} 

const InitializeFilters = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};
/*
const LoadProfession = () => {
    oListProfession.value = [
        {
            ProfessionId: 0,
            Name: 'Todos'
        },
        {
            ProfessionId: 1,
            Name: 'Ing. Mecánica'
        }
    ];
}

const LoadSpecialty = () => {
    oListSpecialty.value = [
        {
            SpecialtyId: 0,
            Name: 'Todos'
        },
        {
            SpecialtyId: 1,
            Name: 'Metales Pesados'
        }
    ];
}
*/
const LoadPersonal = async() => {
    bCargando.value = true;    
    const response = await personService.GetPersonByIdService(0, 0).then();
    if(response.code == 200){
        oListPersonal.value = response.data;
        bCargando.value = false;
    }
}

const AddPersonal = () => {
    if(oListSelected.value.length > 0){
        const newPersonal = [];
        newPersonal.value = oListSelected.value.map(item => ({
            jobPositionId: positionJobIdSelected.value,
            personId: item.personId
        }));

        const request = {
            creationUser: userStore.user.idUser,
            persons: newPersonal.value
        };

        confirm.require({
            message: `¿Está seguro de agregar las personas seleccionadas\n al puesto ${positionJobNameSelected.value}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            acceptClass: 'p-button-primary',
            accept: async () => {
                try{
                    const response = await personService.CreateUpdatePersonInsertToJobPositionService(request);
                    if(response.code == 201){
                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: `Se guardaron las personas correctamente`,
                            accept: async() => {
                                emit('sendList', oListSelected);
                                closeModalHandler();
                            }
                        });
                    }
                }
                catch (error) {
                    bCargando.value = false;
                    console.error(`Error al Guardar:`, error);
                }
            }
        });
    }
    else{
        confirm.require({
            group: 'msgWarning',
            header: 'Alerta',
            message: `Debe seleccionar al menos un colaborador`,
            accept: () => {
                bCargando.value = false;
            }
        });
    }
}

const closeModalHandler = () => {
  emit('closeModal');
}

const NewPersonal = () => {
    const projectId = encodeURIComponent(Crypto.Encriptar(projectIdSelected.value));
    const positionId = encodeURIComponent(Crypto.Encriptar(positionJobIdSelected.value));
    router.push({ path: `/portal/Accreditation/Personal/${projectId}/${positionId}` });
}
//#endregion
</script>


<style scoped>
.chart-container {
    flex: 1; /* Permite que el contenedor del gráfico crezca para ocupar el espacio restante */
    display: flex;
    justify-content: center;
    align-items: center; /* Centra el gráfico */
}
</style>
